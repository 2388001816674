import React from "react"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

class EventAgenda extends React.Component {
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName("tabs_item")
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none"
    }

    tablinks = document.getElementsByTagName("li")
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "")
    }

    document.getElementById(tabNmae).style.display = "block"
    evt.currentTarget.className += "current"
  }
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      data2: [],
      data3: [],
      data4: [],
      loading: true,
      error: null,
    }
  }

  componentDidMount() {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
    const allData = `{
    "meta": {
        "code": 200,
        "status": "success",
        "message": "Data list agenda berhasil diambil"
    },
    "data": [
    {
      "id": 1,
      "title": "Opening Ceremony",
      "description": "INDONESIA MODERN CULTURAL ART SHOW & STORY TELLING",
      "organizer": "Opening Act by TEAM RORO",
      "start_time": "09:30:00",
      "end_time": "10:30:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 2,
      "title": "Talkshow SME's",
      "description": "Ecofashion: Pioneering Sustainable Fashion in a Circular Economy",
      "organizer": [
        "Indonesian Fashion Designer Council (IFDC)",
        "House of Inang",
        "Indonesian Trade Attaché Paris",
        "Mrs. Paula Verhoeven (Prominent Indonesian Fashion Icon & Influencer)"
      ],
      "start_time": "11:00:00",
      "end_time": "12:00:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 3,
      "title": "Talkshow Tourism",
      "description": "“Discovering Indonesia's Top 5 Sustainable Tourism Destinations: Borobudur, Lake Toba, Mandalika, Raja Ampat, and Likupang”",
      "organizer": [
        "Ministry of Tourism of the Republic of Indonesia",
        "Association of Indonesian Tourism Industry (GIPI)",
        "Netherlands Board of Tourism & Conventions (NBTC)",
        "Indonesian Embassy in The Hague"
      ],
      "start_time": "12:30:00",
      "end_time": "13:30:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 4,
      "title": "Music Performances / Playback Music ",
      "description": "",
      "organizer": "",
      "start_time": "13:30:00",
      "end_time": "14:00:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 5,
      "title": "Talkshow SME's",
      "description": "Indonesian Restaurants in the Netherlands: Navigating Challenges and Opportunities in the Food Supply Chain",
      "organizer": [
        "Indonesian Trade Attaché The Hague",
        "Indonesian Chamber of Commerce and Industry (KADIN ITH)",
        "Indonesian Hotel and Restaurant Association (PHRI)",
        "Mr. Galih Bulgamin (Owner Bali Brunch)",
        "Mrs. Veronica Herlina (Founder Saka Dala)"
      ],
      "start_time": "14:30:00",
      "end_time": "15:30:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 6,
      "title": "Talkshow Tourism",
      "description": "Sustainable Pathways: Exploring Eco-Tourism Villages & Regenerative Tourism Initiatives in Indonesia",
      "organizer": [
        "Mr. Fahad Attamimi (Special Staff to the Ministry of Villages, Disadvantaged Regions)",
        "PT Pertamina (Persero)",
        "Mrs. Adinda Moeda (Sanggar Cendana NTT)",
        "KEMBERIN Association",
        "Mr. Muktisjah (Association of Indonesian Entrepreneurs in the Netherlands (ASPINA))"
      ],
      "start_time": "15:45:00",
      "end_time": "16:45:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 7,
      "title": "Art Performances",
      "description": "NUSANTARA THEME: Stories of historical journeys, culture, diversity will be conveyed in a storytelling style that gives a deeper meaning to Indonesia more deeply, through songs, dances and narratives that will be performed.",
      "organizer": "Danceamble by #TEAMRORO",
      "start_time": "17:00:00",
      "end_time": "17:10:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-28"
    },
    {
      "id": 8,
      "title": "Talkshow SME's",
      "description": "Indonesian Modest Fashion: Expanding Market Potential in Europe",
      "organizer": [
        "Ministry of SMEs of the Republic of Indonesia",
        "Tya Ariestya (Catalyst for SMEs and Prominent Indonesian Influencer)",
        "Indonesian Trade Attaché The Hague",
        "Mr. Annas Ardiansyah (Association of Indonesian Entrepreneurs in the Netherlands (ASPINA))",
        "Ms. Megan Lee (Owner Megansartworks)"
      ],
      "start_time": "11:00:00",
      "end_time": "12:00:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 9,
      "title": "Talkshow Tourism",
      "description": "The Hidden Gems of Indonesia: Unveiling Unique Travel Experiences for European Explorers",
      "organizer": [
        "Ministry of Tourism of the Republic of Indonesia",
        "Association of The Indonesian Tours and Travel Agencies (ASITA)",
        "Panorama Destination",
        "Travel Agent / Tour Operator / Tourism Board from Netherlands"
      ],
      "start_time": "12:15:00",
      "end_time": "13:15:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 10,
      "title": "Entertainment Dance / Collab ",
      "description": "Bentang Budaya Nusantara",
      "organizer": "Sanggar Cendana X #TEAMRORO",
      "start_time": "13:45:00",
      "end_time": "13:55:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 11,
      "title": "Talkshow SME's",
      "description": "Indonesian Handicrafts, Home Decor & Furniture: Navigating Export Challenges and Opportunities in Europe",
      "organizer": [
        "WUIH Community",
        "Indonesian Furniture Industry and Handicraft Association (ASMINDO)",
        "Association of Indonesian Handicraft Exporters and Producers (ASEPHI)",
        "Mr. Annas Ardiansyah (Association of Indonesian Entrepreneurs in the Netherlands (ASPINA))",
        "Mr. Bayu Wicaksono (Indonesian Trade Attaché Berlin)",
        "Buyers Representative"
      ],
      "start_time": "13:55:00",
      "end_time": "14:55:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 12,
      "title": "Talkshow Tourism",
      "description": "Bridging Continents: Boosting European Tourism to Indonesia through B2B Collaboration between Indonesia and The Netherlands",
      "organizer": [
        "Association of The Indonesian Tours and Travel Agencies (ASITA)",
        "Mr. Syafii Kamil (Association of Indonesian Entrepreneurs in the Netherlands (ASPINA))",
        "Panorama Destination",
        "Travel Agent / Tour Operator"
      ],
      "start_time": "15:10:00",
      "end_time": "16:10:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 13,
      "title": "Music Performances / Playback Music ",
      "description": "",
      "organizer": "",
      "start_time": "16:10:00",
      "end_time": "16:40:00",
      "location": "B2B",
      "views": 0,
      "date": "2024-11-29"
    },
    {
      "id": 14,
      "title": "Traditional Indonesian dances",
      "description": "Kolaborasi Tari Negeriku",
      "organizer": "Sanggar Kolaborasi Belanda, a dance collaboration project by Nova Burdo-Marseline and Rahmida Dewi Patmawati",
      "start_time": "11:30:00",
      "end_time": "11:35:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 15,
      "title": "Workshop",
      "description": "The Cultural & Art Traditions of Indonesia",
      "organizer": "Peduli Seni Indonesia by Mrs. Deby Subiyanti",
      "start_time": "11:35:00",
      "end_time": "12:20:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 16,
      "title": "Cultural Parade",
      "description": "Cultural Parade of Indonesian Heritage (regional traditional clothes)",
      "organizer": "#TEAMRORO",
      "start_time": "12:20:00",
      "end_time": "12:35:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 17,
      "title": "Workshop Jamu",
      "description": "Jamu Making: Learn the art of Jamu, a traditional herbal medicine from Indonesia.",
      "organizer": "Djamu-djamu",
      "start_time": "12:35:00",
      "end_time": "13:35:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 18,
      "title": "Art Performances",
      "description": "Danceamble",
      "organizer": "#TEAMRORO",
      "start_time": "13:35:00",
      "end_time": "13:45:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 19,
      "title": "Music Performances",
      "description": "Angklung Performance",
      "organizer": "Angklung Melodi Bambu",
      "start_time": "13:45:00",
      "end_time": "14:15:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 20,
      "title": "Fashion Show x Cultural Performances",
      "description": "Happa | Adinda Moeda | House of Inang |Margaria x Enom | Uzy Fauziah",
      "organizer": "STUDIO ONE x #TEAMRORO",
      "start_time": "15:15:00",
      "end_time": "15:55:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 21,
      "title": "Shadow Puppets Show",
      "description": "Rainbow Leaf Of The Ancient Tree",
      "organizer": "Ki Tetuko Aji",
      "start_time": "16:10:00",
      "end_time": "17:00:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-11-30"
    },
    {
      "id": 22,
      "title": "Workshop",
      "description": "Papercraft Ichinogami",
      "organizer": "Ichinogami",
      "start_time": "11:00:00",
      "end_time": "11:30:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 23,
      "title": "Shadow Puppets Show",
      "description": "The Tale of Dewa Ruci",
      "organizer": "Ki Tetuko Aji",
      "start_time": "11:50:00",
      "end_time": "12:40:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 24,
      "title": "Music Performances / Playback Music ",
      "description": "",
      "organizer": "",
      "start_time": "12:55:00",
      "end_time": "13:25:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 25,
      "title": "Workshop",
      "description": "Workshop Self Love with Sanga sanga Oil",
      "organizer": "PT Kutus Kutus Herbal (SANGA SANGA)",
      "start_time": "13:20:00",
      "end_time": "14:25:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 26,
      "title": "Cultural Parade",
      "description": "Cultural Parade of Indonesian Heritage (regional traditional clothes)",
      "organizer": "#TEAMRORO",
      "start_time": "14:25:00",
      "end_time": "14:40:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 27,
      "title": "Modest Fashion Festival",
      "description": "Tya Ariestya by Beli Mukena | Bhumitala |Radiance of the East | Haidee & Orlin",
      "organizer": "by Aida Nurmala - Studio One",
      "start_time": "15:10:00",
      "end_time": "16:10:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    },
    {
      "id": 28,
      "title": "Closing Ceremony",
      "description": "INDONESIA Dalam Sejarah,INDONESIA Kini dan Nanti(closing with Flash Mob Maumere Dance)",
      "organizer": "Cultural Performance - Team RORO in Collab with Sanggar Cendana NTT & Mrs Debby Subiyanti",
      "start_time": "16:10:00",
      "end_time": "16:55:00",
      "location": "B2C",
      "views": 0,
      "date": "2024-12-01"
    }
  ]
}`
    const allData2 = JSON.parse(allData).data
    const dates = ["2024-11-28", "2024-11-29", "2024-11-30", "2024-12-01"]

    const sortByStartTime = (a, b) => {
      if (a.start_time < b.start_time) return -1
      if (a.start_time > b.start_time) return 1
      return 0
    }

    const [data, data2, data3, data4] = dates.map((date) =>
      allData2
        .filter((item) => item && item.date === date)
        .sort(sortByStartTime)
    )

    this.setState({
      data,
      data2,
      data3,
      data4,
      loading: false,
    })
  }
  render() {
    const { data, data2, data3, data4, loading, error } = this.state
    const formatTimeCEST = (time) => {
      const date = new Date(`1970-01-01T${time}`)
      return date.toLocaleTimeString("nl-NL", {
        hour: "2-digit",
        minute: "2-digit",
      })
    }

    const formatDate = (dateString) => {
      const { i18n } = this.props
      const date = new Date(dateString)
      return date.toLocaleDateString(i18n.language, {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    }
    const { t } = this.props

    if (loading) {
      return <div>{t("agenda.schedule.loading")}</div>
    }

    if (error) {
      return <div>{t("agenda.schedule.error")}</div>
    }

    return (
      <section className="schedule-area bg-image ptb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tab">
                <ul className="tabs active">
                  <li
                    onClick={(e) => this.openTabSection(e, "tab1")}
                    className="current"
                  >
                    <Link to="#">
                      {t("agenda.schedule.days.first")}
                      <span>{formatDate("2024-11-28")}</span>
                    </Link>
                  </li>

                  <li onClick={(e) => this.openTabSection(e, "tab2")}>
                    <Link to="#">
                      {t("agenda.schedule.days.second")}
                      <span>{formatDate("2024-11-29")}</span>
                    </Link>
                  </li>

                  <li onClick={(e) => this.openTabSection(e, "tab3")}>
                    <Link to="#">
                      {t("agenda.schedule.days.third")}
                      <span>{formatDate("2024-11-30")}</span>
                    </Link>
                  </li>

                  <li onClick={(e) => this.openTabSection(e, "tab4")}>
                    <Link to="#">
                      {t("agenda.schedule.days.fourth")}
                      <span>{formatDate("2024-12-01")}</span>
                    </Link>
                  </li>
                </ul>

                <div className="tab_content">
                  <div id="tab1" className="tabs_item">
                    <ul className="accordion">
                      {data.map((agenda) => (
                        <li key={agenda.id} className="accordion-item">
                          <p className="accordion-title" to="#">
                            {agenda.speakers && agenda.speakers.length > 0 && (
                              <div className="author author-multi">
                                {/* Assuming there might be multiple speakers, we're just selecting the first one here */}
                                {agenda.speakers.map((speaker) => (
                                  <img
                                    key={speaker.id}
                                    src={speaker.image}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={speaker.name}
                                    alt="Author"
                                  />
                                ))}
                              </div>
                            )}

                            <div className="schedule-list container">
                              <div className="schedule-item row border-bottom py-3">
                                <div className="col-md-9">
                                  <h3
                                    className="h5 font-weight-bold"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    {agenda.title}
                                  </h3>
                                  <p className="small text-muted">
                                    {agenda.description}
                                  </p>
                                  {agenda.organizer && (
                                    <p className="small text-muted">
                                      <b>Organizer:</b>
                                      <br />
                                      {Array.isArray(agenda.organizer)
                                        ? agenda.organizer.map((org, index) => (
                                            <span key={index}>
                                              {org}
                                              <br />
                                            </span>
                                          ))
                                        : agenda.organizer}
                                    </p>
                                  )}
                                </div>
                                <div className="col-md-3 text-md-right">
                                  <p
                                    className="h5 font-weight-bold mb-0"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    <i className="icofont-wall-clock text-success mr-2"></i>{" "}
                                    {formatTimeCEST(agenda.start_time)} -{" "}
                                    {formatTimeCEST(agenda.end_time)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </p>

                          <div className="accordion-content">
                            <p>{agenda.description}</p>

                            <div className="row h-100 align-items-center">
                              <div className="col-lg-6 col-md-7">
                                <div className="location">
                                  <b>Location:</b> {agenda.location}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-5 text-right">
                                <Link to="#" className="btn btn-primary">
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div id="tab2" className="tabs_item">
                    <ul className="accordion">
                      {data2.map((agenda) => (
                        <li key={agenda.id} className="accordion-item">
                          <Link className="accordion-title" to="#">
                            {agenda.speakers && agenda.speakers.length > 0 && (
                              <div className="author author-multi">
                                {/* Assuming there might be multiple speakers, we're just selecting the first one here */}
                                {agenda.speakers.map((speaker) => (
                                  <img
                                    key={speaker.id}
                                    src={speaker.image}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={speaker.name}
                                    alt="Author"
                                  />
                                ))}
                              </div>
                            )}

                            <div className="schedule-list container">
                              <div className="schedule-item row border-bottom py-3">
                                <div className="col-md-9">
                                  <h3
                                    className="h5  font-weight-bold"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    {agenda.title}
                                  </h3>
                                  <p className="small text-muted">
                                    {agenda.description}
                                  </p>
                                  {agenda.organizer && (
                                    <p className="small text-muted">
                                      <b>Organizer:</b>
                                      <br />
                                      {Array.isArray(agenda.organizer)
                                        ? agenda.organizer.map((org, index) => (
                                            <span key={index}>
                                              {org}
                                              <br />
                                            </span>
                                          ))
                                        : agenda.organizer}
                                    </p>
                                  )}
                                </div>
                                <div className="col-md-3 text-md-right">
                                  <p
                                    className="h5  font-weight-bold mb-0"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    <i className="icofont-wall-clock text-success mr-2"></i>{" "}
                                    {formatTimeCEST(agenda.start_time)} -{" "}
                                    {formatTimeCEST(agenda.end_time)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>

                          <div className="accordion-content">
                            <p>{agenda.description}</p>

                            <div className="row h-100 align-items-center">
                              <div className="col-lg-6 col-md-7">
                                <div className="location">
                                  <b>Location:</b> {agenda.location}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-5 text-right">
                                <Link to="#" className="btn btn-primary">
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div id="tab3" className="tabs_item">
                    <ul className="accordion">
                      {data3.map((agenda) => (
                        <li key={agenda.id} className="accordion-item">
                          <Link className="accordion-title" to="#">
                            {agenda.speakers && agenda.speakers.length > 0 && (
                              <div className="author author-multi">
                                {/* Assuming there might be multiple speakers, we're just selecting the first one here */}
                                {agenda.speakers.map((speaker) => (
                                  <img
                                    key={speaker.id}
                                    src={speaker.image}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={speaker.name}
                                    alt="Author"
                                  />
                                ))}
                              </div>
                            )}

                            <div className="schedule-list container">
                              <div className="schedule-item row border-bottom py-3">
                                <div className="col-md-9">
                                  <h3
                                    className="h5  font-weight-bold"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    {agenda.title}
                                  </h3>
                                  <p className="small text-muted">
                                    {agenda.description}
                                  </p>
                                  {agenda.organizer && (
                                    <p className="small text-muted">
                                      <b>Organizer:</b>
                                      <br />
                                      {Array.isArray(agenda.organizer)
                                        ? agenda.organizer.map((org, index) => (
                                            <span key={index}>
                                              {org}
                                              <br />
                                            </span>
                                          ))
                                        : agenda.organizer}
                                    </p>
                                  )}
                                </div>
                                <div className="col-md-3 text-md-right">
                                  <p
                                    className="h5  font-weight-bold mb-0"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    <i className="icofont-wall-clock text-success mr-2"></i>{" "}
                                    {formatTimeCEST(agenda.start_time)} -{" "}
                                    {formatTimeCEST(agenda.end_time)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>

                          <div className="accordion-content">
                            <p>{agenda.description}</p>

                            <div className="row h-100 align-items-center">
                              <div className="col-lg-6 col-md-7">
                                <div className="location">
                                  <b>Location:</b> {agenda.location}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-5 text-right">
                                <Link to="#" className="btn btn-primary">
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div id="tab4" className="tabs_item">
                    <ul className="accordion">
                      {data4.map((agenda) => (
                        <li key={agenda.id} className="accordion-item">
                          <Link className="accordion-title" to="#">
                            {agenda.speakers && agenda.speakers.length > 0 && (
                              <div className="author author-multi">
                                {/* Assuming there might be multiple speakers, we're just selecting the first one here */}
                                {agenda.speakers.map((speaker) => (
                                  <img
                                    key={speaker.id}
                                    src={speaker.image}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={speaker.name}
                                    alt="Author"
                                  />
                                ))}
                              </div>
                            )}

                            <div className="schedule-list container">
                              <div className="schedule-item row border-bottom py-3">
                                <div className="col-md-9">
                                  <h3
                                    className="h5  font-weight-bold"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    {agenda.title}
                                  </h3>
                                  <p className="small text-muted">
                                    {agenda.description}
                                  </p>
                                  {agenda.organizer && (
                                    <p className="small text-muted">
                                      <b>Organizer:</b>
                                      <br />
                                      {Array.isArray(agenda.organizer)
                                        ? agenda.organizer.map((org, index) => (
                                            <span key={index}>
                                              {org}
                                              <br />
                                            </span>
                                          ))
                                        : agenda.organizer}
                                    </p>
                                  )}
                                </div>
                                <div className="col-md-3 text-md-right">
                                  <p
                                    className="h5  font-weight-bold mb-0"
                                    style={{ color: "#1f3d19" }}
                                  >
                                    <i className="icofont-wall-clock text-success mr-2"></i>{" "}
                                    {formatTimeCEST(agenda.start_time)} -{" "}
                                    {formatTimeCEST(agenda.end_time)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>

                          <div className="accordion-content">
                            <p>{agenda.description}</p>

                            <div className="row h-100 align-items-center">
                              <div className="col-lg-6 col-md-7">
                                <div className="location">
                                  <b>Location:</b> {agenda.location}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-5 text-right">
                                <Link to="#" className="btn btn-primary">
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="btn-box">
                <a
                  href="/RUNDOWN-DMI-EXPO-2024.pdf"
                  download
                  className="btn btn-primary"
                  aria-label="Download event schedule PDF"
                >
                  {t("agenda.schedule.downloadPdf")}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
        </div>
        <div className="shape2 rotateme">
          <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
        </div>
        <div className="shape3 rotateme">
          <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
        </div>
        <div className="shape4">
          <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
        </div>
      </section>
    )
  }
}

export default withTranslation()(EventAgenda)
