const hotelData = [
  {
    id: 1,
    name: "PARTNER HOTEL WITH SPECIAL PRICE",
    image:
      "https://res.cloudinary.com/dvnpm2hid/image/upload/v1728567332/dmiexpo/mbnzf9qxt3lypctrps1z.png",
    stars: 3,
    price: "Include City Tax <br>Free Shuttle",
    distance: "550 m (on foot) - 1,6 km (by car)",
    mapLink: "https://maps.app.goo.gl/cfFpjEa8nUSNUxF56",
  },
  {
    id: 2,
    name: "Holiday Inn Express Utrecht - Papendorp, an IHG Hotel",
    image:
      "https://digital.ihg.com/is/image/ihg/holiday-inn-express-utrecht-8806299615-4x3?wid=733",
    stars: 3,
    price: "Rp 2.292.362",
    distance: "- 3,7 - 4,5 km (by car)",
    mapLink: "https://maps.app.goo.gl/qYdZNYGXncmreLry9",
  },
  {
    id: 3,
    name: "Ibis Utrecht",
    image:
      "https://cdn.ibis-hotel-utrecht.nl/wp-content/uploads/2019/01/ibis-Utrecht-Exterior-AMP.jpg",
    stars: 3,
    price: "Rp 2.036.493 - Rp 2.652.575",
    distance: "- 1,3 - 1,4 km (on foot) <br>- 1,4 - 2,2 km (by car)",
    mapLink: "https://maps.app.goo.gl/14LgWPo1EheY3uxZA",
  },
  {
    id: 6,
    name: "Inntel Hotels Utrecht Centre",
    image:
      "https://www.inntelhotelsutrechtcentre.nl/wp-content/uploads/sites/5/2018/11/Spa-Corner-Angle-2-1.jpg",
    stars: 4,
    price: "Rp 2.052.746 - Rp 4.755.482",
    distance: "- 500 - 800 m (on foot) <br>- 1,4 km (by car)",
    mapLink: "https://maps.app.goo.gl/BnWvVwfWjj2SvgBn6",
  },
  {
    id: 7,
    name: "The Anthony Hotel Utrecht",
    image:
      "https://theanthony.nl/wp-content/uploads/2021/05/TheAnthony-GardenYard-1.jpg",
    stars: 4,
    price: "Rp 3.780.686 - Rp 5.199.706",
    distance: "- 600 m (on foot) <br>- 1,1 km (by car)",
    mapLink: "https://maps.app.goo.gl/3fXe9XFy59sdDHsh9",
  },
  {
    id: 8,
    name: "Crowne Plaza Utrecht - Central Station, an IHG Hotel",
    image:
      "https://digital.ihg.com/is/image/ihg/crowne-plaza-utrecht-6592366929-3x2?wid=733",
    stars: 5,
    price: "Rp 2.753.948 - Rp 3.814.474",
    distance: "- 600 m (on foot) <br>- 1,8 km (by car)",
    mapLink: "https://maps.app.goo.gl/GxhUAmx2jvFVUbUQ8",
  },
  {
    id: 9,
    name: "Grand Hotel Karel V",
    image: "https://www.karelv.nl/wp-content/uploads/2021/11/drone.jpg",
    stars: 5,
    price: "Rp 3.592.034 - Rp 4.807.117",
    distance: "- 1 - 1.5 km (on foot) <br>- 2,7 - 3,6 km (by car)",
    mapLink: "https://maps.app.goo.gl/Kb2oYyd8Dh8DCFru9",
  },
]

export default hotelData
