import React from "react"
import ReadMoreNewsBtn from "../home-default/latest-news-module/ReadMoreNewsBtn"
import { Link } from "react-router-dom"
import "./NewsCard.css"

const NewsCard = ({ post }) => {
  return (
    <div className="col-lg-12 col-md-12" key={post.image}>
      <div className="single-blog-post single-blog-post-v2">
        <div className="blog-image">
          <Link to={`news/${post.slug}`}>
            <img src={post.image} alt={post.title} />
          </Link>

          {/* <div className="post-tag">
                        <Link to={`news/${post.slug}`}>
                          {post.category.charAt(0).toUpperCase() +
                            post.category.slice(1)}
                        </Link>
                      </div> */}
        </div>

        <div className="blog-post-content">
          <span className="date date-v2">{formatDate(post.created_at)}</span>
          <h3 className="post-title-v2">
            <Link to={`news/${post.slug}`}>{post.title}</Link>
          </h3>
          <div
            dangerouslySetInnerHTML={{
              __html: post.content.slice(0, 300),
            }}
            className="post-content-v2"
          />

          <ReadMoreNewsBtn link={`news/${post.slug}`} text="Read More" />
        </div>
      </div>
    </div>
  )
}

export default NewsCard

function formatDate(dateString) {
  const date = new Date(dateString)
  const options = { day: "numeric", month: "long", year: "numeric" }
  const formattedDate = date.toLocaleDateString("en-GB", options)
  return `${formattedDate}`
}
