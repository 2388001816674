import React from "react"
import ModalVideo from "react-modal-video"
import "./ButtonStyles.css"
import { withTranslation } from "react-i18next"
import PlaceAndDate from "../v2/home-default/main-banner-module/PlaceAndDate"
import EventCountDownTimer from "../v2/home-default/main-banner-module/EventCountDownTimer"
import MyButton from "../ui/MyButton"

class MainBanner extends React.Component {
  state = {
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
    isOpen: false,
  }

  openModal = () => {
    this.setState({ isOpen: true })
  }

  makeTimer = () => {
    let endTime = new Date("October 30 2025 08:00:00 PDT")
    let endTimeParse = Date.parse(endTime) / 1000
    let now = new Date()
    let nowParse = Date.parse(now) / 1000
    let timeLeft = endTimeParse - nowParse
    let days = Math.floor(timeLeft / 86400)
    let hours = Math.floor((timeLeft - days * 86400) / 3600)
    let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60)
    let seconds = Math.floor(
      timeLeft - days * 86400 - hours * 3600 - minutes * 60
    )
    if (hours < "10") {
      hours = "0" + hours
    }
    if (minutes < "10") {
      minutes = "0" + minutes
    }
    if (seconds < "10") {
      seconds = "0" + seconds
    }
    this.setState({
      days,
      hours,
      minutes,
      seconds,
    })
  }

  componentDidMount() {
    this.myInterval = setInterval(() => {
      this.makeTimer()
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.myInterval)
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="cRXm1p-CNyk"
          onClose={() => this.setState({ isOpen: false })}
        />

        <div className="main-banner progressive-background">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "32px",
                    "@media (minWidth: 768px)": {
                      gap: "20px",
                    },
                  }}
                >
                  <img
                    src={require("../../assets/images/dmi_white.png")}
                    alt="DMI Logo"
                    style={{
                      width: "100%",
                      maxWidth: "1000px",
                    }}
                  />
                  <EventCountDownTimer
                    dayTime={this.state.days}
                    hourTime={this.state.hours}
                    minuteTime={this.state.minutes}
                    secondTime={this.state.seconds}
                  />
                  <PlaceAndDate />
                  {/* <MyButton>
                    <a
                      href={`${process.env.REACT_APP_API_BASE_URL}`}
                      rel="noopener noreferrer"
                    >
                      {t("general.buyTicket")}
                    </a>
                  </MyButton> */}
                </div>
              </div>
            </div>
          </div>

          <div className="shape1">
            <img
              src={require("../../assets/images/shapes/1.png")}
              alt="shape1"
            />
          </div>

          <div className="shape2 rotateme">
            <img
              src={require("../../assets/images/shapes/2.png")}
              alt="shape2"
            />
          </div>

          <div className="shape3 rotateme">
            <img
              src={require("../../assets/images/shapes/3.png")}
              alt="shape3"
            />
          </div>

          <div className="shape4">
            <img
              src={require("../../assets/images/shapes/4.png")}
              alt="shape4"
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(MainBanner)
