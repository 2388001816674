import React from "react";
import { Link } from "react-router-dom";
import "isomorphic-fetch";
// import Footer from "../Common/Footer";
import { withTranslation } from "react-i18next";
import FooterV2 from "../v2/layout/FooterV2";

class Contact extends React.Component {
  state = {
    submitting: false,
    submitted: false,
    buttonState: "",
    formFields: {
      name: "",
      email: "",
      phone: "",
      role: "",
      origin: "",
    },
  };

  onSubmit = (e) => {
    e.preventDefault();
    const data = this.state.formFields;
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    fetch(`${apiBaseUrl}/api/delegation`, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ submitted: true });
      }
      let formFields = Object.assign({}, this.state.formFields);
      formFields.name = "";
      formFields.email = "";
      formFields.phone = "";
      formFields.role = "";
      formFields.origin = "";
      this.setState({ formFields });
    });
  };

  nameChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.name = e.target.value;
    this.setState({ formFields });
  };

  emailChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.email = e.target.value;
    this.setState({ formFields });
  };

  phoneChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.phone = e.target.value;
    this.setState({ formFields });
  };

  textChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.message = e.target.value;
    this.setState({ formFields });
  };

  // create change handler for role and origin
  roleChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.role = e.target.value;
    this.setState({ formFields });
  };

  originChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.origin = e.target.value;
    this.setState({ formFields });
  };

  onHideSuccess = () => {
    this.setState({ submitted: false });
  };

  successMessage = () => {
    const { t } = this.props;
    if (this.state.submitted) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          style={{ marginTop: "14px" }}
        >
          <strong>{t("delegation.form.success.title")}</strong>
          <br />
          {t("delegation.form.success.message")}
        </div>
      );
    }
  };
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="page-title-area item-bg1">
          <div className="container">
            <h1>{t("delegation.title")}</h1>
            <ul>
              <li>
                <Link to="/">{t("delegation.breadcrumb.home")}</Link>
              </li>
              <li>{t("delegation.breadcrumb.delegation")}</li>
            </ul>
          </div>
        </div>

        <section className="contact-area ptb-120">
          <div className="container">
            <div className="row h-100 align-items-center contact-form">
              <div className="col-lg-12 col-md-12">
                <form onSubmit={this.onSubmit} id="contactForm">
                  <div className="row">
                    <div id="msgSubmit" className="h3 text-center hidden">
                      {this.successMessage()}
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="name">
                          {t("delegation.form.fields.name.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          required={true}
                          data-error={t("delegation.form.fields.name.error")}
                          value={this.state.formFields.name}
                          onChange={this.nameChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="email">
                          {t("delegation.form.fields.email.label")}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          required={true}
                          data-error={t("delegation.form.fields.email.error")}
                          value={this.state.formFields.email}
                          onChange={this.emailChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="number">
                          {t("delegation.form.fields.phone.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="number"
                          id="number"
                          required={true}
                          data-error={t("delegation.form.fields.phone.error")}
                          value={this.state.formFields.phone}
                          onChange={this.phoneChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="role">
                          {t("delegation.form.fields.role.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="role"
                          id="role"
                          required={true}
                          data-error={t("delegation.form.fields.role.error")}
                          value={this.state.formFields.role}
                          onChange={this.roleChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="origin">
                          {t("delegation.form.fields.origin.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="origin"
                          id="origin"
                          required={true}
                          data-error={t("delegation.form.fields.origin.error")}
                          value={this.state.formFields.origin}
                          onChange={this.originChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button
                        type="submit"
                        className="btn btn-primary animated-button"
                      >
                        {t("delegation.form.submit")}
                      </button>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    );
  }
}

export default withTranslation()(Contact);
