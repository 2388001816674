import React from "react";
import { Link } from "react-router-dom";
import "react-accessible-accordion/dist/fancy-example.css";
// import Footer from "../Common/Footer";
import FooterV2 from "../v2/layout/FooterV2";

class Faq extends React.Component {
  submitHandler = (e) => {
    e.preventDefault();
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg2">
          <div className="container">
            <h1>Privacy Policy</h1>
            <span>Privacy Policy</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Privacy Policy</li>
            </ul>
          </div>
        </div>

        <section className="faq-area ptb-120">
          <div className="container">
            <div className="faq-contact">
              <h3>Privacy Policy</h3>
              {/* div text align left */}
              <div style={{ textAlign: "left" }}>
                <h1>Privacy Policy</h1>
                <p>
                  Thank you for choosing to use our online ticketing website for
                  events. This Privacy Policy outlines how we collect, use,
                  disclose, and protect your personal information when you visit
                  and interact with our website. We value your privacy and are
                  committed to safeguarding your personal data. By using our
                  website, you consent to the practices described in this
                  Privacy Policy.
                </p>
                <h2>1. Information We Collect</h2>
                <p>
                  <strong>1.1 Personal Information:</strong> We may collect the
                  following types of personal information from you: Full name,
                  Contact information (email address, phone number, mailing
                  address), Billing information (credit card details, payment
                  preferences), Demographic information (age, gender,
                  interests), Any other information you voluntarily provide to
                  us.
                </p>
                <p>
                  <strong>1.2 Automatically Collected Information:</strong> When
                  you access our website, we may automatically collect certain
                  information, such as your IP address, browser type, device
                  information, and operating system. This information is
                  collected through the use of cookies and similar technologies.
                </p>
                <h2>2. Use of Information</h2>
                <p>
                  <strong>2.1 Personal Information:</strong> We may use your
                  personal information for the following purposes: To process
                  and fulfill your ticket purchase or registration, To
                  communicate with you regarding your ticket purchase, event
                  updates, and customer support, To provide you with information
                  about upcoming events, promotions, and special offers, To
                  personalize your experience on our website and tailor content
                  to your preferences, To improve our services, website
                  functionality, and user experience, To detect and prevent
                  fraudulent or unauthorized activities, To comply with legal
                  obligations and resolve disputes.
                </p>
                <p>
                  <strong>2.2 Automatically Collected Information:</strong> The
                  automatically collected information is used for statistical
                  analysis, website administration, and to improve our services
                  and user experience. This information may also be used to
                  deliver targeted advertisements.
                </p>
                <h2>3. Disclosure of Information</h2>
                <p>
                  <strong>3.1 Third-Party Service Providers:</strong> We may
                  disclose your personal information to trusted third-party
                  service providers who assist us in operating our website,
                  processing payments, conducting marketing campaigns, and
                  providing customer support. These service providers are
                  contractually obligated to protect your personal information
                  and are prohibited from using it for any other purpose.
                </p>
                <p>
                  <strong>3.2 Legal Requirements:</strong> We may disclose your
                  personal information if required to do so by law, in response
                  to a legal request, or to protect our rights, property, or
                  safety, or the rights, property, or safety of others.
                </p>
                <p>
                  <strong>3.3 Business Transfers:</strong> In the event of a
                  merger, acquisition, or sale of our business assets, your
                  personal information may be transferred to the acquiring
                  entity as part of the transaction.
                </p>
                <h2>4. Data Security</h2>
                <p>
                  We take reasonable measures to protect your personal
                  information from unauthorized access, disclosure, alteration,
                  or destruction. These measures include encryption, access
                  controls, regular security assessments, and training our
                  employees on data protection practices. However, no method of
                  transmission over the internet or electronic storage is 100%
                  secure, and we cannot guarantee absolute security.
                </p>
                <h2>5. Third-Party Links</h2>
                <p>
                  Our website may contain links to third-party websites. We are
                  not responsible for the privacy practices or content of these
                  websites. We encourage you to review the privacy policies of
                  those third parties before providing any personal information.
                </p>
                <h2>6. Children's Privacy</h2>
                <p>
                  Our website is not intended for children under the age of 13.
                  We do not knowingly collect personal information from children
                  under 13. If you believe we have collected personal
                  information from a child under 13, please contact us
                  immediately, and we will take steps to remove that information
                  from our database.
                </p>
                <h2>7. Your Choices</h2>
                <p>
                  You have the right to: Access, update, or delete your personal
                  information by contacting us directly, Opt-out of receiving
                  marketing communications, Disable cookies through your browser
                  settings. Please note that some features of our website may
                  not function properly if cookies are disabled.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    );
  }
}

export default Faq;
