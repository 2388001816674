import React from "react"
import { Link } from "react-router-dom"
import { Star } from "lucide-react"
import LaxDiv from "../Shared/LaxDiv"
import { withTranslation } from "react-i18next"

const cuisineData = [
  {
    id: 1,
    name: "Broadway",
    image:
      "https://exploreutrecht.nl/wp-content/uploads/2015/08/20150603_184303_1_resized.jpg",
    address: "Oudegracht aan de Werf 139, 3511 AL Utrecht",
    distance:
      "7 Minutes (by car) 2km<br>14 Minutes (on foot)<br>6 Minutes (by bicycle)",
    mapLink: "https://maps.app.goo.gl/acCLKbqqqWF1bJRY8",
  },
  {
    id: 2,
    name: "Fort De Gagel",
    image:
      "https://cdn.prod.website-files.com/64e5b8e9808d5d4f8198eca6/6555f71a50511e7b6ae20d2b_Fort%20de%20Gagel4.webp",
    address: "Gageldijk 167, 3566 MJ Utrecht",
    distance:
      "13 Minutes (by car) 7Km<br>1 Jam 30 Minutes (on foot)<br>21 Minutes (by bicycle)",
    mapLink: "https://maps.app.goo.gl/X3XH9yG6xU1wMfL56",
  },
  {
    id: 3,
    name: "Liemes",
    image:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1d/e8/4f/0f/mooi-eten-prachtige-zonsonderg.jpg?w=1200&h=-1&s=1",
    address: "Proostwetering 11, 3543 AB Utrecht",
    distance:
      "8 Minutes (by car) 6Km<br>1 Jam 2 Minutes (on foot)<br>18 Minutes (by bicycle)",
    mapLink: "https://maps.app.goo.gl/P92neBjgUEXnxQMS9",
  },
  {
    id: 4,
    name: "Restaurant Saffraan (Mediterania, Timur Tengah)",
    image:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/1b/84/aa/bf/interieur-saffraan.jpg?w=1200&h=-1&s=1",
    address: "Oudegracht aan de Werf 146, 3511 AZ Utrecht, Netherlands",
    distance: "4,2 km (by car)<br>1,0 km (on foot)",
    mapLink: "https://maps.app.goo.gl/XA9CH6bcwUbV4of8A",
  },
  {
    id: 5,
    name: "Helal burgers & shoarma (Amerika, Makanan Cepat Saji, Turki)",
    image:
      "https://media-cdn.tripadvisor.com/media/photo-s/12/c4/c9/57/photo0jpg.jpg",
    address: "Kanaalstraat 58-50, 3531 CK Utrecht, Netherlands",
    distance: "2,6 km (by car)<br>700 - 850 m (on foot)",
    mapLink: "https://maps.app.goo.gl/Vv19QDm6oRmxVQWJ8",
  },
  {
    id: 6,
    name: "Anjappar Utrecht (Indian Resto)",
    image: "https://anjappar.nl/wp-content/uploads/2023/08/image-28.png",
    address: "Vredenburg 28, 3511 BC Utrecht",
    distance: "2,2 km (by car)<br>800 - 850 m (on foot)",
    mapLink: "https://maps.app.goo.gl/JmUbvbQxHyHLgkQ97",
  },
  {
    id: 7,
    name: "Restaurant Place",
    image:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/21/f6/d6/88/interior.jpg?w=600&h=-1&s=1",
    address: "Vredenburg 35, 3511 BD Utrecht",
    distance: "2,1 km (by car)<br>750 - 850 m (on foot)",
    mapLink: "https://maps.app.goo.gl/mSeQ6oTWZfKUQCzn9",
  },
  {
    id: 8,
    name: "Halal Fried Chicken",
    image:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0d/78/04/e8/img-20161030-wa0015-largejpg.jpg?w=1200&h=-1&s=1",
    address: "Van Starkenborghhof 90, 3527 HB Utrecht, The Netherlands",
    distance: "1,9 km (by car)<br>1,9 km (on foot)",
    mapLink: "https://maps.app.goo.gl/SJvMdKSpgYHKRgQR8",
  },
  {
    id: 9,
    name: "Klein Parijs",
    image:
      "https://qul.imgix.net/23b619e1-7a96-4b04-8e19-c23ccb20f2d8/361553_landscape.jpg",
    address: "Bosboomstraat 24, 3582 KH Utrecht",
    distance: "3,5 - 4,0 km (by car)<br>3,0 - 3,2 km (on foot)",
    mapLink: "https://maps.app.goo.gl/4bFiE1wdbVuMryYN7",
  },
  // Add more cuisine objects here
]

class Cuisines extends React.Component {
  renderStars(count) {
    return Array(count)
      .fill()
      .map((_, i) => <Star key={i} size={16} fill="gold" color="gold" />)
  }

  renderCuisine(cuisine) {
    return (
      <div className="col-lg-4 col-md-6" key={cuisine.id}>
        <div className="single-speakers-box">
          <div className="speakers-image">
            <div className="image-container">
              <img src={cuisine.image} alt={cuisine.name} />
            </div>
          </div>

          <div className="speakers-content">
            <h3>
              <a
                href={cuisine.mapLink}
                target="_blank"
                rel="noopener noreferrer"
                to="#"
              >
                {cuisine.name}
              </a>
            </h3>
            <br />
            <span className="price">{cuisine.address}</span>
            <span
              className="distance"
              dangerouslySetInnerHTML={{ __html: cuisine.distance }}
            ></span>

            <ul className="social">
              <li>
                <a
                  href={cuisine.mapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icofont-link"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t } = this.props
    return (
      <section className="speakers-area-two ptb-120">
        <div className="container">
          <div className="section-title">
            <span>{t("cuisine.title")}</span>
            <h2>
              {t("cuisine.subtitle")} <b>{t("cuisine.subtitleElement")}</b>{" "}
              <br />
            </h2>

            <LaxDiv text={t("cuisine.title")} dataPreset="driftLeft" />

            <div className="bar"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {cuisineData.map((cuisine) => this.renderCuisine(cuisine))}
          </div>
        </div>
        <style jsx>{`
          .image-container {
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio */
            position: relative;
            overflow: hidden;
          }
          .image-container img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .btn-box {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
      </section>
    )
  }
}

export default withTranslation()(Cuisines)
