import React from "react";
import MainBanner from "../AboutOne/MainBanner";
import AboutComponent from "../HomeDefault/About";
import GoTop from "../Shared/GoTop";
// import Footer from "../Common/Footer";
import lax from "lax.js";
import FooterV2 from "../v2/layout/FooterV2";

class About extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }
  render() {
    return (
      <React.Fragment>
        <MainBanner />
        <AboutComponent />
        {/* <Footer /> */}
        <FooterV2 />
        <GoTop scrollStepInPx="50" delayInMs="16.66" />
      </React.Fragment>
    );
  }
}

export default About;
