import React from "react"
import { Link } from "react-router-dom"
import ReadMoreNewsBtn from "../home-default/latest-news-module/ReadMoreNewsBtn"
import "./HotelCard.css"

const HotelCard = ({ post }) => {
  return (
    <div className="col-lg-12 col-md-12" key={post.id}>
      <div className="single-blog-post single-blog-post-v2">
        <div className="blog-image">
          <Link to={`hotels`}>
            <div className="image-container">
              <img src={post.image} alt={post.name} />
            </div>
          </Link>
        </div>

        <div className="blog-post-content hotel-content-v2">
          <h3 className="hotel-title-v2">
            <Link to={`hotels`}>{post.name}</Link>
          </h3>
          <div className="hotel-content-wrapper-v2">
            <PriceTagIcon />
            <div
              dangerouslySetInnerHTML={{
                __html: post.price,
              }}
              className="hotel-content-v2"
            />
          </div>

          <ReadMoreNewsBtn link={`hotels`} text="Read More" />
        </div>
      </div>
    </div>
  )
}

export default HotelCard

const PriceTagIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      fill="currentColor"
      class="icon icon-tabler icons-tabler-filled icon-tabler-tags"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9.172 5a3 3 0 0 1 2.121 .879l5.71 5.71a3.41 3.41 0 0 1 0 4.822l-3.592 3.592a3.41 3.41 0 0 1 -4.822 0l-5.71 -5.71a3 3 0 0 1 -.879 -2.121v-4.172a3 3 0 0 1 3 -3zm-2.172 4h-.01a1 1 0 1 0 .01 2a1 1 0 0 0 0 -2" />
      <path d="M14.293 5.293a1 1 0 0 1 1.414 0l4.593 4.592a5.82 5.82 0 0 1 0 8.23l-1.592 1.592a1 1 0 0 1 -1.414 -1.414l1.592 -1.592a3.82 3.82 0 0 0 0 -5.402l-4.592 -4.592a1 1 0 0 1 0 -1.414" />
    </svg>
  )
}
