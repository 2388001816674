import React from "react";
import { Link } from "react-router-dom";
import "react-accessible-accordion/dist/fancy-example.css";
// import Footer from "../Common/Footer";
import FooterV2 from "../v2/layout/FooterV2";

class Faq extends React.Component {
  submitHandler = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg2">
          <div className="container">
            <h1>GDPR</h1>
            <span>GDPR Consent</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>GDPR Consent</li>
            </ul>
          </div>
        </div>

        <section className="faq-area ptb-120">
          <div className="container">
            <div className="faq-contact">
              <h3>GDPR Consent</h3>
              {/* div text align left */}
              <div style={{ textAlign: "left" }}>
                <h2>GDPR Consent</h2>
                <p>
                  In compliance with the General Data Protection Regulation
                  (GDPR), we require your consent to collect and process your
                  personal data. Please read our{" "}
                  <a href="/privacy-policy">Privacy Policy</a> to understand how
                  we handle your data.
                </p>
                <div>
                  <label htmlFor="consentCheckbox">
                    <input
                      type="checkbox"
                      id="consentCheckbox"
                      // checked={consentGiven}
                      // onChange={handleConsentChange}
                    />
                    I give my consent to the collection and processing of my
                    personal data as described in the Privacy Policy.
                  </label>
                </div>
                <button>Continue</button>
              </div>
            </div>
          </div>
        </section>

        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    );
  }
}

export default Faq;
