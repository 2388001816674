import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translations from "./translations.json"

// Get language from localStorage or default to 'en'
const savedLanguage = localStorage.getItem("language") || "en"

i18n.use(initReactI18next).init({
  resources: translations,
  lng: savedLanguage, // use saved language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
})

// Listen for language changes and save to localStorage
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("language", lng)
})

export default i18n
