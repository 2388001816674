import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "./FooterV2.css";

class FooterV2 extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <footer className="footer-area footer-area-v2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="single-footer-widget footer-widget-v2">
                <h3>{t("footer.venue.title")}</h3>
                <span>
                  <i className="icofont-calendar"></i> {t("footer.venue.date")}
                </span>

                <p className="location location-v2">
                  <i className="icofont-google-map"></i>{" "}
                  {t("footer.venue.location")}
                </p>
                <br />

                <h3>{t("footer.contact.title")}</h3>
                <p className="contact contact-v2">
                  <i className="icofont-email"></i> {t("footer.contact.email1")}
                  <br />
                  <i className="icofont-email"></i> {t("footer.contact.email2")}
                </p>
                <Link to="#" className="contact-authority contact-authority-v2">
                  <i className="icofont-phone"></i> {t("footer.contact.phone")}
                </Link>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="single-footer-widget footer-widget-v2">
                <h3>{t("footer.social.title")}</h3>
                <p>{t("footer.social.description")}</p>

                <ul className="social-links social-links-v2">
                  <li>
                    <a
                      href="https://www.facebook.com/people/DMI-EXPO/61557413708202/"
                      className="facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/100969554/admin/settings/"
                      className="linkedin"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icofont-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/dmi_expo?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                      className="instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icofont-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="copyright-area copyright-area-v2">
                <ul>
                  <li>
                    <Link to="/privacy-policy">
                      {t("footer.links.privacy")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-of-services">
                      {t("footer.links.terms")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/gdpr-compliance">{t("footer.links.gdpr")}</Link>
                  </li>
                </ul>
                <p>{t("footer.copyright")}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(FooterV2);
