import "./SponsorCard.css";

const SponsorCard = ({ data, title, isGoldSponsor = false }) => {
  return (
    <div className={`sponsor-card ${isGoldSponsor && "gold-sponsor"}`}>
      <h3>{title}</h3>

      <div className="sponsor-border"></div>

      {data.map((sponsor) => (
        <a
          href={sponsor.link}
          key={sponsor.id}
          target="_blank"
          rel="noopener noreferrer"
          className="sponsor-image-card"
        >
          <img src={sponsor.image} alt={`${sponsor.name} Logo`} />
        </a>
      ))}
    </div>
  );
};

export default SponsorCard;
