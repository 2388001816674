import React from "react";
import OwlCarousel from "react-owl-carousel3";
import lax from "lax.js";
import "./LatestNewsV2.css";
import axios from "axios";
import { withTranslation } from "react-i18next";
import FunctionalLaxDiv from "../../shared/FunctionalLaxDiv";
import ViewAllNewsBtn from "./ViewAllNewsBtn";
import NewsCard from "../../shared/NewsCard";

const options = {
  loop: false,
  nav: false,
  dots: true,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

const CACHE_KEY = "latestNewsPosts";
const CACHE_EXPIRATION = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

class LatestNewsV2 extends React.Component {
  state = {
    posts: [],
    currentPage: 1,
    totalPages: 1,
  };

  componentDidMount() {
    this.fetchBlogPosts(this.state.currentPage);
  }

  async fetchBlogPosts(page) {
    const cachedData = this.getCachedData();
    if (cachedData) {
      this.setState({
        posts: cachedData.posts,
        currentPage: cachedData.currentPage,
        totalPages: cachedData.totalPages,
        loading: false,
      });
      return;
    }

    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(`${apiBaseUrl}/api/news?page=1&limit=7`);
      const { data, current_page, last_page } = response.data.data;

      const newState = {
        posts: data,
        currentPage: current_page,
        totalPages: last_page,
        loading: false,
      };

      this.setState(newState);
      this.cacheData(newState);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
      this.setState({ error: "Failed to fetch blog posts", loading: false });
    }
  }

  getCachedData() {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_EXPIRATION) {
        return data;
      }
    }
    return null;
  }

  cacheData(data) {
    const cacheData = {
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
  }
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }
  render() {
    const { t } = this.props;
    const { posts } = this.state;
    return (
      <section className="news-area-v2">
        <div className="container">
          <div className="news-new-section-title">
            <h2>{t("news.subtitle")}</h2>

            <div className="lax-absolute">
              <FunctionalLaxDiv text="News" dataPreset="driftLeft" />
            </div>

            <ViewAllNewsBtn link={"news"} text={t("general.viewAll")} />
          </div>

          <div className="row blog-slides-wrapper-v2">
            <OwlCarousel
              className="blog-slides owl-carousel owl-theme"
              {...options}
            >
              {posts.map((post) => (
                <NewsCard post={post} key={post.id} />
              ))}{" "}
              {/* space in the end is important for owl carousel */}
            </OwlCarousel>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(LatestNewsV2);
