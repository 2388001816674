import React from "react";
import { Link } from "react-router-dom";
import "isomorphic-fetch";
// import Footer from "../Common/Footer";
import { withTranslation } from "react-i18next";
import FooterV2 from "../v2/layout/FooterV2";

class Contact extends React.Component {
  state = {
    submitting: false,
    submitted: false,
    buttonState: "",
    formFields: {
      name: "",
      email: "",
      phone: "",
      text: "",
    },
  };

  onSubmit = (e) => {
    e.preventDefault();
    const data = this.state.formFields;
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    fetch(`${apiBaseUrl}/api/inquiry`, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ submitted: true });
      }
      let formFields = Object.assign({}, this.state.formFields);
      formFields.name = "";
      formFields.email = "";
      formFields.phone = "";
      formFields.message = "";
      this.setState({ formFields });
    });
  };

  // ... keep your existing change handlers ...

  successMessage = () => {
    const { t } = this.props;
    if (this.state.submitted) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          style={{ marginTop: "14px" }}
        >
          <strong>{t("contact.form.success.title")}</strong> <br />
          {t("contact.form.success.message")}
        </div>
      );
    }
  };

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="page-title-area item-bg1">
          <div className="container">
            <h1>{t("contact.title")}</h1>
            <ul>
              <li>
                <Link to="/">{t("contact.breadcrumb.home")}</Link>
              </li>
              <li>{t("contact.breadcrumb.contact")}</li>
            </ul>
          </div>
        </div>

        <section className="contact-area ptb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-phone"></i>
                  </div>
                  <div className="content">
                    <h4>{t("contact.info.phone.title")}</h4>
                    <p>{t("contact.info.phone.value")}</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-email"></i>
                  </div>
                  <div className="content">
                    <h4>{t("contact.info.email.title")}</h4>
                    <p>{t("contact.info.email.value")}</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-world"></i>
                  </div>
                  <div className="content">
                    <h4>{t("contact.info.location.title")}</h4>
                    <p>{t("contact.info.location.value")}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row h-100 align-items-center contact-form">
              <div className="col-lg-4 col-md-12">
                <div className="leave-your-message">
                  <h3>{t("contact.form.title")}</h3>
                  <p>{t("contact.form.description")}</p>

                  <div className="stay-connected">
                    <h3>{t("contact.social.title")}</h3>
                    <ul>
                      <li>
                        <a
                          href="https://negeriku.nl/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="icofont-globe"></i>
                          <span>{t("contact.social.website")}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-12">
                <form onSubmit={this.onSubmit} id="contactForm">
                  <div className="row">
                    <div id="msgSubmit" className="h3 text-center hidden">
                      {this.successMessage()}
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">
                          {t("contact.form.fields.name")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          required={true}
                          data-error={t("contact.form.errors.name")}
                          value={this.state.formFields.name}
                          onChange={this.nameChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="email">
                          {t("contact.form.fields.email")}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          required={true}
                          data-error={t("contact.form.errors.email")}
                          value={this.state.formFields.email}
                          onChange={this.emailChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="number">
                          {t("contact.form.fields.phone")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="number"
                          id="number"
                          required={true}
                          data-error={t("contact.form.errors.phone")}
                          value={this.state.formFields.phone}
                          onChange={this.phoneChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="message">
                          {t("contact.form.fields.message")}
                        </label>
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          cols="30"
                          rows="4"
                          required={true}
                          data-error={t("contact.form.errors.message")}
                          value={this.state.formFields.message}
                          onChange={this.textChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button type="submit" className="btn btn-primary">
                        {t("contact.form.submit")}
                      </button>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    );
  }
}

export default withTranslation()(Contact);
