import React from "react"
import { Link } from "react-router-dom"
import "isomorphic-fetch"
// import Footer from "../Common/Footer";
// import LaxButton from "../Shared/LaxButton";
import LaxDiv from "../Shared/LaxDiv"
import { withTranslation } from "react-i18next"
import FooterV2 from "../v2/layout/FooterV2"

class Business extends React.Component {
  state = {
    submitting: false,
    submitted: false,
    buttonState: "",
    formFields: {
      profile_name: "",
      email: "",
      phone: "",
    },
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <div className="page-title-area item-bg1">
          <div className="container">
            <h1>{t("businessMeeting.title")}</h1>
            <ul>
              <li>
                <Link to="/">{t("businessMeeting.breadcrumb.home")}</Link>
              </li>
              <li>{t("businessMeeting.breadcrumb.business")}</li>
            </ul>
          </div>
        </div>
        <section className="about-area-two ptb-120 bg-image">
          <div className="container">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6">
                <div className="about-content">
                  <span>{t("businessMeeting.about.joinEvent")}</span>
                  <h2>{t("businessMeeting.about.title")}</h2>
                  <p>{t("businessMeeting.about.description1")}</p>
                  <p>{t("businessMeeting.about.description2")}</p>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="about-image">
                  <img
                    src={`https://res.cloudinary.com/dvnpm2hid/image/upload/v1728924712/dmiexpo/hlgfn4oddbeyx6kzlmqh.png`}
                    className="about-img1"
                    alt="about"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pricing-area ptb-120 bg-image">
          <div className="container">
            <div className="section-title">
              <span>{t("businessMeeting.pricing.title")}</span>
              <h2>
                {t("businessMeeting.pricing.getYourSeat")}{" "}
                <b>{t("businessMeeting.pricing.seat")}</b>
              </h2>

              <LaxDiv
                text={t("businessMeeting.title")}
                dataPreset="driftLeft"
              />

              <div className="bar"></div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="pricing-table-box">
                  <div className="pricingTable-header">
                    <h3 className="title">
                      {t("businessMeeting.pricing.buyer.title")}
                    </h3>
                    <div className="price-value">
                      <sup> </sup>
                    </div>
                  </div>

                  <ul className="pricing-content">
                    {t("businessMeeting.pricing.buyer.features", {
                      returnObjects: true,
                    }).map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                  <div className="button-container">
                    <a
                      href="https://business.dmiexpo.id/registration"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary hover:opacity-90 transition-opacity"
                      aria-label="Register for business meeting"
                    >
                      {t("businessMeeting.pricing.buyer.register")}
                    </a>

                    <a
                      href="https://business.dmiexpo.id/login"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-secondary hover:opacity-90 transition-opacity"
                      aria-label="Login to business meeting"
                    >
                      {t("businessMeeting.pricing.buyer.login")}
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="pricing-table-box">
                  <div className="pricingTable-header">
                    <h3 className="title">Exhibitor</h3>
                    <div className="price-value">
                      <sup> </sup>
                    </div>
                  </div>

                  <ul className="pricing-content">
                    <li>Showcase Products</li>
                    <li>Meet Buyers</li>
                    <li>Business Networking</li>
                  </ul>
                  <div className="button-container">
                    <a
                      href="https://business.dmiexpo.id/login"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-secondary hover:opacity-90 transition-opacity"
                      aria-label="Login as exhibitor"
                    >
                      {t("businessMeeting.pricing.exhibitor.login")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
        <FooterV2 />

        <style jsx>{`
          .button-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 3rem;
            margin-top: 2rem;
          }
        `}</style>
      </React.Fragment>
    )
  }
}

export default withTranslation()(Business)
