import React from "react";
import OwlCarousel from "react-owl-carousel3";
import lax from "lax.js";
// import LaxDiv from "../Shared/LaxDiv";
import { withTranslation } from "react-i18next";
import hotelData from "./hotel-data";
import FunctionalLaxDiv from "../../shared/FunctionalLaxDiv";
import ViewAllNewsBtn from "../latest-news-module/ViewAllNewsBtn";
import "./HomeHotels.css";
import HotelCard from "../../shared/HotelCard";

const options = {
  loop: false,
  nav: false,
  dots: true,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

class Hotels extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }
  render() {
    const { t } = this.props;
    return (
      <section className="hotels-area-v2">
        <div className="container">
          <div className="hotels-new-section-title">
            <h2>
              {t("hotels.subtitle")} {t("hotels.subtitleElement")}
            </h2>

            <div className="lax-absolute">
              <FunctionalLaxDiv text="Hotels" dataPreset="driftLeft" />
            </div>

            <ViewAllNewsBtn link={"hotels"} text={`${t("general.viewAll")}`} />
          </div>

          <div className="row blog-slides-wrapper-v2">
            <OwlCarousel
              className="blog-slides owl-carousel owl-theme"
              {...options}
            >
              {hotelData.map((hotel) => (
                <HotelCard post={hotel} />
              ))}{" "}
            </OwlCarousel>
          </div>
        </div>
        <style jsx>{`
          .image-container {
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio */
            position: relative;
            overflow: hidden;
          }
          .image-container img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .btn-box {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
      </section>
    );
  }
}

export default withTranslation()(Hotels);
