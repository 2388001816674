import React, { useState, useEffect } from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom"
import HomeDefault from "./components/pages/HomeDefault"
import Navigation from "./components/Navigation/Navigation"
import About from "./components/pages/About"
import Hotels from "./components/pages/Hotels"
import Agenda from "./components/pages/Agenda"
import NotFound from "./components/pages/NotFound"
import Faq from "./components/pages/Faq"
import Contact from "./components/pages/Contact"
import News from "./components/pages/News"
import Gallery from "./components/pages/Gallery"
import Sponsor from "./components/pages/Sponsor"
import SponsorApplication from "./components/pages/Sponsor/Application"
import Privacy from "./components/pages/Privacy"
import Terms from "./components/pages/Terms"
import GDPR from "./components/pages/GDPR"
import Business from "./components/pages/Business"
import ExhibitorApplication from "./components/pages/Exhibitor/Application"
import Delegation from "./components/pages/Delegation"
import NewsDetail from "./components/pages/NewsDetail"
import GalleryDetail from "./components/pages/GalleryDetail"

// Conditionally render Navigation with isMainPage prop
const AppRouter = () => {
  const location = useLocation()

  // Conditional boolean to make the nav in the main page and the other page different
  const [isMainPage, setIsMainPage] = useState(location.pathname === "/")

  const shouldRenderNavigation = () => {
    const excludedPaths = ["/login", "/signup", "/coming-soon", "/error-404"]
    return !excludedPaths.includes(location.pathname)
  }

  useEffect(() => {
    // Update isMainPage state based on the current path
    const excludePaths = ["/login", "/signup", "/coming-soon", "/error-404"]
    setIsMainPage(
      location.pathname === "/" && !excludePaths.includes(location.pathname)
    )
  }, [location.pathname])

  return (
    <React.Fragment>
      {shouldRenderNavigation() && <Navigation isMainPage={isMainPage} />}
      <Switch>
        <Route path="/" exact component={HomeDefault} />
        <Route path="/terms-of-services" exact component={Terms} />
        <Route path="/privacy-policy" exact component={Privacy} />
        <Route path="/gdpr-compliance" exact component={GDPR} />
        <Route path="/about" exact component={About} />
        <Route path="/hotels" exact component={Hotels} />
        <Route path="/agenda" exact component={Agenda} />
        <Route path="/faq" exact component={Faq} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/news" exact component={News} />
        <Route path="/news/:slug" exact component={NewsDetail} />
        <Route path="/gallery" exact component={Gallery} />
        <Route path="/gallery/:id" exact component={GalleryDetail} />
        <Route path="/sponsors" exact component={Sponsor} />
        <Route
          path="/sponsors-application"
          exact
          component={SponsorApplication}
        />
        <Route path="/business-meeting" exact component={Business} />
        <Route
          path="/exhibitor-application"
          exact
          component={ExhibitorApplication}
        />
        {/* <Route path="/delegation" exact component={Delegation} /> */}
        <Route path="/error-404" exact component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  )
}

const App = () => (
  <Router>
    <AppRouter />
  </Router>
)

export default App
