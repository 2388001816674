import { Link } from "react-router-dom";
import "./ReadMoreNewsBtn.css";

const ReadMoreNewsBtn = ({ link, text, ...props }) => {
  return (
    <button className="read-more-news-btn" {...props}>
      <Link to={link}>
        {text}
        <Icon />
      </Link>
    </button>
  );
};

export default ReadMoreNewsBtn;

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em" // Set width to em to be responsive
      height="1.5em" // Set height to em to be responsive
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      class="icon icon-tabler icons-tabler-outline icon-tabler-chevrons-right"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M7 7l5 5l-5 5" />
      <path d="M13 7l5 5l-5 5" />
    </svg>
  );
};
