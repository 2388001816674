import React from "react";
// import MainBanner from "../Hotels/MainBanner";
import Hotel from "../Hotels/Hotels";
import Transportation from "../Hotels/Transportations";
import Destination from "../Hotels/Destinations";
import Cuisine from "../Hotels/Cuisine";
// import Footer from "../Common/Footer";
import FooterV2 from "../v2/layout/FooterV2";

class Hotels extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <MainBanner /> */}
        <Hotel />
        <Transportation />
        <Destination />
        <Cuisine />
        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    );
  }
}

export default Hotels;
