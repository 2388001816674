import React from "react"
import { Link } from "react-router-dom"
import { Star } from "lucide-react"
import LaxDiv from "../Shared/LaxDiv"
import { withTranslation } from "react-i18next"

const destinationData = [
  {
    id: 1,
    name: "Hoog Catharijne",
    type: "Mall",
    image:
      "https://www.klepierre.com/assets/f1920x1080-q85/1b917258/k_hoog_catharijne_mail_004.jpg",
    address: "Godebaldkwartier 54, 3511 DX Utrecht, Netherlands",
    distance:
      "8 minutes (by car) 2.9 km <br>10 minutes (on foot) 750 m<br>2 minutes (by bicycle) 600 m",
    mapLink: "https://maps.app.goo.gl/QBTRqhHBCQojyF8r7",
  },
  {
    id: 2,
    name: "Speelklok",
    type: "Museum",
    image:
      "https://cdn.bafageh.com/blogs/content/1694275076_Screenshot%202023-09-09%20225553.png",
    address: "Steenweg 6, 3511 JP Utrecht, Netherlands",
    distance:
      "9 minutes (by car) 3.2 km<br>15 minutes (on foot) 1km<br>6 minutes (by bicycle) 1.5km",
    mapLink: "https://maps.app.goo.gl/w3i5X2t43JHDjYgh6",
  },
  {
    id: 3,
    name: "Schuttevaer Boat Cruise",
    type: "Pelayaran",
    image:
      "https://www.schuttevaer.com/wp-content/uploads/sites/4446/2020/10/Hop-on-Hop-off-image-1.jpg?resize=1200%2C900&zoom=2",
    address: "Oudegracht 85, 3511 AD Utrecht, Netherlands",
    distance:
      "8 minutes (by car) 2 km<br>14 minutes (on foot) 1 km<br>5 minutes (by bicycle) 950 m",
    mapLink: "https://maps.app.goo.gl/i2ZyyGeeMWixFsdU9",
  },
  {
    id: 4,
    name: "De Haar Castle",
    type: "Castle",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXMuEz-DhbY0zCUezyhdDugph_yKIkoqcWQg&s",
    address: "Castle De Haar, Kasteellaan 1, 3455 RR Utrecht, Netherlands",
    distance:
      "18 minutes (by car) 11Km<br>2 Jam (on foot)<br>36 Minutes (by bicycle)",
    mapLink: "https://maps.app.goo.gl/2RYm82dgMCh9D1oy8",
  },
  {
    id: 5,
    name: "Utrecht Botanic Gardens",
    type: "Botanic Garden",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/FortHoofddijk01.jpg/1200px-FortHoofddijk01.jpg",
    address: "Budapestlaan 17, 3584 CD Utrecht, Netherlands",
    distance: "7.1 - 10.1  km (by car)<br>5,8 km (on foot)",
    mapLink: "https://maps.app.goo.gl/hNHffEcHY3igKbfN8",
  },
  {
    id: 6,
    name: "Wilhelminapark",
    type: "Taman",
    image:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0b/69/3e/4c/nice-and-calm-park-to.jpg?w=1200&h=-1&s=1",
    address: "Wilhelminapark 17, 3581 ND Utrecht, Netherlands",
    distance: "4,6 km (by car)<br>3,2 km (on foot)",
    mapLink: "https://maps.app.goo.gl/TMRms8ARNs7FUNn28",
  },
  {
    id: 7,
    name: "Rietveld Schröder House",
    type: "Bangunan Bersejarah",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbBpOcbl_NNFio4Ch4Y9ppvkP1TFK--tmkbg&s",
    address: "Prins Hendriklaan 50, 3583 EP Utrecht, Netherlands",
    distance: "5,3 km (by car)<br>3,7 km (on foot)",
    mapLink: "https://maps.app.goo.gl/4vmxPAXHwBRrQaJx7",
  },
  {
    id: 8,
    name: "Utrecht Souvenirs",
    type: "Toko Sovenir",
    image:
      "https://c8.alamy.com/comp/2GNG8YM/holland-souvenir-shop-in-utrecht-netherlands-2GNG8YM.jpg",
    address: "Lichte Gaard 1, 3511 KT Utrecht, Netherlands",
    distance: "4,6 km (by car)<br>2,0 km (on foot)",
    mapLink: "https://maps.app.goo.gl/j4gijLNbUhTH8RHL7",
  },
  {
    id: 9,
    name: "Dom Tower",
    type: "Cultural Landmark",
    image:
      "https://comedywalks.com/wp-content/uploads/sites/2927/2023/08/Utrecht-Dom-Tower.png?w=700&h=700&zoom=2",
    address: "Domplein 9, 3512 JC Utrecht, Netherlands",
    distance: "6,5 km (by car)<br>2,0 km (on foot)",
    mapLink: "https://maps.app.goo.gl/jCyBTpEtYq3FvL9H8",
  },
  {
    id: 10,
    name: "The Railway Museum",
    type: "Museum",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/6/6e/Rijksmonument-36180.jpg",
    address: "Maliebaanstation 16, 3581 XW Utrecht, Netherlands",
    distance: "4,8 km (by car)<br>2,5 km (on foot)",
    mapLink: "https://maps.app.goo.gl/M7FfvngbZvuL6t848",
  },
  {
    id: 11,
    name: "St Martin's Cathedral",
    type: "Gereja",
    image:
      "https://www.whatsoninutrecht.com/wp-content/uploads/2018/07/St.-Martins-Cathedral.jpg",
    address: "Achter de Dom 1, 3512 JN Utrecht, Netherlands",
    distance: "6,1 km (by car)<br>2,2 km (on foot)",
    mapLink: "https://maps.app.goo.gl/kNb9JB7V1X5Y76Ab6",
  },
  // Add more destination objects here
]

class Destinations extends React.Component {
  renderStars(count) {
    return Array(count)
      .fill()
      .map((_, i) => <Star key={i} size={16} fill="gold" color="gold" />)
  }

  renderDestination(destination) {
    return (
      <div className="col-lg-4 col-md-6" key={destination.id}>
        <div className="single-speakers-box">
          <div className="speakers-image">
            <div className="image-container">
              <img src={destination.image} alt={destination.name} />
            </div>
          </div>

          <div className="speakers-content">
            <h3>
              <a
                href={destination.mapLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {destination.name}
              </a>
            </h3>
            <span className="price">{destination.type}</span>
            <br />
            <span className="price">{destination.address}</span>
            <span
              className="distance"
              dangerouslySetInnerHTML={{ __html: destination.distance }}
            ></span>

            <ul className="social">
              <li>
                <a
                  href={destination.mapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icofont-link"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t } = this.props
    return (
      <section className="speakers-area-two ptb-120">
        <div className="container">
          <div className="section-title">
            <span>{t("destination.title")}</span>
            <h2>
              {t("destination.subtitle")}{" "}
              <b>{t("destination.subtitleElement")}</b> <br />
            </h2>

            <LaxDiv text={t("destination.title")} dataPreset="driftLeft" />

            <div className="bar"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {destinationData.map((destination) =>
              this.renderDestination(destination)
            )}
          </div>
        </div>
        <style jsx>{`
          .image-container {
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio */
            position: relative;
            overflow: hidden;
          }
          .image-container img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .btn-box {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
      </section>
    )
  }
}

export default withTranslation()(Destinations)
