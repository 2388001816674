import React from "react";
import { withTranslation } from "react-i18next";
import "./BuyTicketV2.css";
import BuyTicketBtn from "./BuyTicketBtn";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
class BuyTicketV2 extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <section className="buy-tickets-area buy-tickets-area-v2">
        <div className="container">
          <div className="buy-tickets-new-section-title">
            <div>
              <h2>{t("ticket.bookTicket")}</h2>
              <p>{t("ticket.subtitle")}</p>
            </div>

            {/* <ViewAllNewsBtn link={"hotels"} text={`${t("general.viewAll")}`} /> */}
            <BuyTicketBtn link={apiBaseUrl} text={t("ticket.button")} />
          </div>
          {/* <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title">
                <h2 className="book-ticket-title-v2">
                  {t("ticket.bookTicket")}
                </h2>
                <p>{t("ticket.subtitle")}</p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="buy-ticket-btn">
                <a
                  href={`${apiBaseUrl}`}
                  rel="noopener noreferrer"
                  className="btn btn-primary animated-button"
                >
                  {t("ticket.button")}
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    );
  }
}

export default withTranslation()(BuyTicketV2);
