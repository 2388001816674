import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import lax from "lax.js"
import OwlCarousel from "react-owl-carousel3"
import PlatinumSponsors from "../LaxButton/PlatinumSponsors"
import GoldSponsors from "../LaxButton/GoldSponsors"
// import Footer from "../Common/Footer";
import { useTranslation } from "react-i18next"
import i18n from "i18next"
import FooterV2 from "../v2/layout/FooterV2"
import PartnerV2 from "../v2/common/PartnerV2"

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1200: {
      items: 5,
    },
  },
}

function Sponsor() {
  const [sponsors, setSponsors] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    lax.setup()
    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    const fetchSponsors = async () => {
      try {
        const sponsorsData = `{
    "meta": {
        "code": 200,
        "status": "success",
        "message": "Data list Sponsor berhasil diambil"
    },
    "data": {
        "current_page": 1,
        "data": [
            {
                "id": 1,
                "email": null,
                "phone": null,
                "name": "Negeriku",
                "image": "https://switchbox.id/wp-content/uploads/2023/09/logo.png",
                "link": "https://negeriku.nl/",
                "type": "organized",
                "status": "active",
                "created_at": "2024-08-25T13:31:14.000000Z",
                "updated_at": "2024-08-25T13:31:14.000000Z"
            },
            {
                "id": 2,
                "email": null,
                "phone": null,
                "name": "Kupu",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/kupu.png",
                "link": "https://www.kupu-gsc.co.id/",
                "type": "co-organized",
                "status": "active",
                "created_at": "2024-08-25T13:31:15.000000Z",
                "updated_at": "2024-08-25T13:31:15.000000Z"
            },
            {
                "id": 3,
                "email": null,
                "phone": null,
                "name": "KBRI Den Haag",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/embassy.jpg",
                "link": "https://indonesia.nl/id/",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:16.000000Z",
                "updated_at": "2024-08-25T13:31:16.000000Z"
            },
            {
                "id": 4,
                "email": null,
                "phone": null,
                "name": "Kemenparekraf",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/ministry.png",
                "link": "https://kemenparekraf.go.id/",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:18.000000Z",
                "updated_at": "2024-08-25T13:31:18.000000Z"
            },
            {
                "id": 5,
                "email": null,
                "phone": null,
                "name": "KADIN",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/KV-09.png",
                "link": "https://kadin.id/",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:19.000000Z",
                "updated_at": "2024-08-25T13:31:19.000000Z"
            },
            {
                "id": 6,
                "email": null,
                "phone": null,
                "name": "ASPINA",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/KV-04.png",
                "link": "https://aspina.nl/",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:20.000000Z",
                "updated_at": "2024-08-25T13:31:20.000000Z"
            },
            {
                "id": 7,
                "email": null,
                "phone": null,
                "name": "GCI",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/green-climate.png",
                "link": "http://greenclimateinternational.org",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:21.000000Z",
                "updated_at": "2024-08-25T13:31:21.000000Z"
            },
            {
                "id": 8,
                "email": null,
                "phone": null,
                "name": "Inisiatif Indonesia",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/KV-05.png",
                "link": "https://www.instagram.com/inisiatif_indo/",
                "type": "supported",
                "status": "active",
                "created_at": "2024-08-25T13:31:22.000000Z",
                "updated_at": "2024-08-25T13:31:22.000000Z"
            },
            {
                "id": 9,
                "email": null,
                "phone": null,
                "name": "PERTAMINA",
                "image": "https://www.pertamina.com/Media/Image/Pertamina.png",
                "link": "https://www.pertamina.com/",
                "type": "sponsored",
                "status": "active",
                "created_at": "2024-08-25T13:31:23.000000Z",
                "updated_at": "2024-08-25T13:31:23.000000Z"
            },
            {
                "id": 10,
                "email": null,
                "phone": null,
                "name": "Atourin",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/atourin.png",
                "link": "https://atourin.com/",
                "type": "partner",
                "status": "active",
                "created_at": "2024-08-25T13:31:25.000000Z",
                "updated_at": "2024-08-25T13:31:25.000000Z"
            },
            {
                "id": 11,
                "email": null,
                "phone": null,
                "name": "Pandi",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/logo-PANDI.png",
                "link": "https://pandi.id/",
                "type": "partner",
                "status": "active",
                "created_at": "2024-08-25T13:31:26.000000Z",
                "updated_at": "2024-08-25T13:31:26.000000Z"
            },
            {
                "id": 12,
                "email": null,
                "phone": null,
                "name": "Goorita",
                "image": "https://switchbox.id/wp-content/uploads/2023/08/goorita.png",
                "link": "https://goorita.com/",
                "type": "partner",
                "status": "active",
                "created_at": "2024-08-25T13:31:27.000000Z",
                "updated_at": "2024-08-25T13:31:27.000000Z"
            },
            {
                "id": 13,
                "email": null,
                "phone": null,
                "name": "Travel Buddies",
                "image": "https://travelbuddies.co.id/wp-content/uploads/2021/12/logo-new.png",
                "link": "https://travelbuddies.co.id/",
                "type": "partner",
                "status": "active",
                "created_at": "2024-08-25T13:31:28.000000Z",
                "updated_at": "2024-08-25T13:31:28.000000Z"
            },
            {
                "id": 14,
                "email": null,
                "phone": null,
                "name": "Asidewi",
                "image": "https://asidewi.id/wp-content/uploads/2021/04/Logo-Asidewi.png",
                "link": "http://asidewi.id/",
                "type": "association",
                "status": "active",
                "created_at": "2024-08-25T13:31:29.000000Z",
                "updated_at": "2024-08-25T13:31:29.000000Z"
            },
            {
                "id": 15,
                "email": null,
                "phone": null,
                "name": "KEMBERIN",
                "image": "https://kemberin.org/wp-content/uploads/2021/08/logo-full-default.png",
                "link": "https://kemberin.org/",
                "type": "association",
                "status": "active",
                "created_at": "2024-08-25T13:31:30.000000Z",
                "updated_at": "2024-08-25T13:31:30.000000Z"
            },
            {
                "id": 16,
                "email": null,
                "phone": null,
                "name": "Kutus kutus",
                "image": "https://kutusofficial.com/wp-content/uploads/2019/08/logokutusofficial.png",
                "link": "https://kutusofficial.com/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:32.000000Z",
                "updated_at": "2024-08-25T13:31:32.000000Z"
            },
            {
                "id": 17,
                "email": null,
                "phone": null,
                "name": "S.id",
                "image": "https://home.s.id/images/sid-neu-logo-dark.svg",
                "link": "https://home.s.id/id",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:33.000000Z",
                "updated_at": "2024-08-25T13:31:33.000000Z"
            },
            {
                "id": 18,
                "email": null,
                "phone": null,
                "name": "Sarinah",
                "image": "https://sarinah.co.id/cfind/source/thumb/images/cover_w276_h67_logo_sarinah_tanpa_tagline-png.png",
                "link": "https://sarinah.co.id/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:34.000000Z",
                "updated_at": "2024-08-25T13:31:34.000000Z"
            },
            {
                "id": 19,
                "email": null,
                "phone": null,
                "name": "Suwe Ora Jamu",
                "image": "https://static.wixstatic.com/media/154aa2_7c70417d33f643efb371f313a6e4ca5b~mv2_d_1417_1417_s_2.png/v1/fill/w_240,h_171,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/154aa2_7c70417d33f643efb371f313a6e4ca5b~mv2_d_1417_1417_s_2.png",
                "link": "https://www.suweorajamu28.com/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:35.000000Z",
                "updated_at": "2024-08-25T13:31:35.000000Z"
            },
            {
                "id": 20,
                "email": null,
                "phone": null,
                "name": "Ina Harmoni Travel",
                "image": "https://res.cloudinary.com/dvnpm2hid/image/upload/v1694490561/sponsor/xaoj7vjwagwlnrwmjswu.jpg",
                "link": "https://www.instagram.com/inaharmonitravel/?hl=en",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:36.000000Z",
                "updated_at": "2024-08-25T13:31:36.000000Z"
            },
            {
                "id": 21,
                "email": null,
                "phone": null,
                "name": "Konawe Selatan",
                "image": "https://konaweselatankab.go.id/img/logo_konsel.png",
                "link": "https://konaweselatankab.go.id/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:37.000000Z",
                "updated_at": "2024-08-25T13:31:37.000000Z"
            },
            {
                "id": 22,
                "email": null,
                "phone": null,
                "name": "Buru Selatan",
                "image": "https://organisasi.burselkab.go.id/wp-content/uploads/2022/07/Lambang_Kabupaten_Buru_Selatan.png",
                "link": "https://burselkab.go.id/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:39.000000Z",
                "updated_at": "2024-08-25T13:31:39.000000Z"
            },
            {
                "id": 23,
                "email": null,
                "phone": null,
                "name": "Belitung",
                "image": "https://belitung.go.id/images/logo-pemda.png",
                "link": "https://belitung.go.id/",
                "type": "exhibitor",
                "status": "active",
                "created_at": "2024-08-25T13:31:40.000000Z",
                "updated_at": "2024-08-25T13:31:40.000000Z"
            },
            {
                "id": 24,
                "email": "-",
                "phone": "-",
                "name": "IFTA",
                "image": "https://res.cloudinary.com/dvnpm2hid/image/upload/v1725553868/sponsor/sponsor_24.png",
                "link": null,
                "type": "association",
                "status": "active",
                "created_at": "2024-09-05T16:28:53.000000Z",
                "updated_at": "2024-09-05T16:31:10.000000Z"
            },
            {
                "id": 26,
                "email": "bca@dmiexpo.id",
                "phone": "00000000",
                "name": "BCA",
                "image": "https://res.cloudinary.com/dvnpm2hid/image/upload/v1728297000/sponsor/sponsor_26.png",
                "link": null,
                "type": "supporting-sponsor",
                "status": "active",
                "created_at": "2024-10-07T04:24:22.000000Z",
                "updated_at": "2024-10-07T10:30:01.000000Z"
            },
            {
                "id": 27,
                "email": "lpei@dmiexpo.id",
                "phone": "000000000",
                "name": "LPEI",
                "image": "https://res.cloudinary.com/dvnpm2hid/image/upload/v1728910366/sponsor/sponsor_27.jpg",
                "link": null,
                "type": "supporting-sponsor",
                "status": "active",
                "created_at": "2024-10-14T12:44:50.000000Z",
                "updated_at": "2024-10-14T12:52:47.000000Z"
            }
        ],
        "first_page_url": "http://127.0.0.1:8000/api/sponsor?page=1",
        "from": 1,
        "last_page": 1,
        "last_page_url": "http://127.0.0.1:8000/api/sponsor?page=1",
        "links": [
            {
                "url": null,
                "label": "&laquo; Previous",
                "active": false
            },
            {
                "url": "http://127.0.0.1:8000/api/sponsor?page=1",
                "label": "1",
                "active": true
            },
            {
                "url": null,
                "label": "Next &raquo;",
                "active": false
            }
        ],
        "next_page_url": null,
        "path": "http://127.0.0.1:8000/api/sponsor",
        "per_page": 100,
        "prev_page_url": null,
        "to": 26,
        "total": 26
    }
}`
        const sponsorsData2 = JSON.parse(sponsorsData).data.data
        const categorizedSponsors = sponsorsData2.reduce((acc, sponsor) => {
          if (!acc[sponsor.type]) {
            acc[sponsor.type] = []
          }
          acc[sponsor.type].push(sponsor)
          return acc
        }, {})

        setSponsors(categorizedSponsors)
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching sponsors", error)
        setIsLoading(false)
      }
    }

    fetchSponsors()
  }, [])

  if (isLoading) {
    return <div>{t("sponsor.loading")}</div>
  }

  return (
    <React.Fragment>
      <div className="page-title-area item-bg2">
        <div className="container">
          <h1>{t("sponsor.title")}</h1>
          <span>{t("sponsor.subtitle")}</span>
          <ul>
            <li>
              <Link to="/">{t("sponsor.breadcrumb.home")}</Link>
            </li>
            <li>{t("sponsor.breadcrumb.sponsors")}</li>
          </ul>
        </div>
      </div>

      <section className="partner-area ptb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-5">
              {i18n.language !== "id" && (
                <div className="sponsor-article">
                  <img
                    className="w-full max-h-[70%] mb-4"
                    src={require("../../assets/images/PERTAMINA.png")}
                    alt="Pertamina"
                  />
                  <h1 className="text-2xl font-bold mb-4">
                    Pertamina TJSL Update: SUSTAINABLE SELF-SUFFICIENT COMMUNITY
                    THROUGH VILLAGE TOURISM PROGRAM
                  </h1>

                  <div className="article-content">
                    <p>
                      <span className="font-normal">
                        The Village Tourism Program, as part of{" "}
                      </span>
                      <strong>
                        Pertamina&rsquo;s Social &amp; Environmental
                        Responsibility (TJSL)
                      </strong>
                      <span className="font-normal">
                        {" "}
                        program in the environmental and economic pillars,
                        encourages the strengthening of skills (upskilling) and
                        capacity building in managing community-based
                        sustainable tourism. The Pertamina Tourism Village
                        Program is an implementation of ESG aspects and supports
                        the{" "}
                      </span>
                      <strong>
                        Sustainable Development Goals (SDGs) point 8, point 11,
                        and point 15.
                      </strong>
                    </p>
                    <p className="mt-4">
                      <span className="font-normal">
                        The Ministry of Tourism and Creative Economy of the
                        Republic of Indonesia nominated the Tourism Village as
                        one of 16 Sustainable Tourism Villages and in the top 50
                        for Indonesia's Best Tourism Village Award. Pertamina
                        also received the Bronze Category award for its
                        community-based tourism village development program from
                        the Ministry of Villages, Development of Disadvantaged
                        Regions, and Transmigration.
                      </span>
                    </p>
                    <p className="mt-4">
                      <span className="font-normal">As a</span>
                      <strong> platinum sponsor</strong>
                      <span className="font-normal"> of the </span>
                      <strong>
                        Discovering The Magnificence of Indonesia (DMI Expo)
                        2024
                      </strong>
                      <span className="font-normal">
                        event, Pertamina&rsquo;s involvement goes beyond
                        financial support. They are actively participating in
                        the event program, reinforcing their commitment to
                        fostering sustainable tourism.
                      </span>
                    </p>
                  </div>
                </div>
              )}

              {i18n.language === "id" && (
                <div className="sponsor-article">
                  <img
                    className="w-full max-h-[70%] mb-4"
                    src={require("../../assets/images/PERTAMINA.png")}
                    alt="Pertamina"
                  />
                  <h1 className="text-2xl font-bold mb-4">
                    Pertamina Mendukung Penuh Discovering the Magnificence of
                    Indonesia Expo 2024: Pertamina Dorong Ekspansi Pariwisata
                    dan UKM Indonesia
                  </h1>

                  <div className="article-content">
                    <p>
                      <strong>
                        <em>
                          Discovering The Magnificence of Indonesia (DMI Expo)
                          2024
                        </em>
                      </strong>{" "}
                      <span className="font-normal">
                        dengan bangga menerima dukungan penuh dari{" "}
                      </span>
                      <strong>PT Pertamina (Persero)</strong>
                      <span className="font-normal"> sebagai </span>
                      <strong>
                        <em>Platinum Sponsor</em>
                      </strong>
                    </p>
                    <p className="mt-4">
                      <span className="font-normal">
                        Selain dukungan finansial, keterlibatan Pertamina dalam
                        DMI 2024 juga terlihat melalui partisipasi aktif mereka
                        dalam program acara.
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/* Supporting Sponsor Articles */}
            <div className="col-lg-12">
              <h2 className="text-3xl font-bold text-center mb-8">
                Supporting Sponsors
              </h2>
              <div className="supporting-sponsor-article p-4 border rounded">
                <h3 className="text-2xl font-bold mb-4">
                  BCA Dukung Penuh Acara Discovering the Magnificence of
                  Indonesia Expo 2024
                </h3>
                <p className="mb-4">
                  PT Bank Central Asia Tbk (BCA) turut mendukung penyelenggaraan
                  acara Discovering the Magnificence of Indonesia (DMI Expo)
                  2024 yang akan berlangsung pada 28 November - 1 Desember 2024
                  di Jaarbeurs, Utrecht, Belanda. Dukungan diberikan BCA sebagai
                  salah satu lembaga perbankan, untuk membantu promosi
                  pariwisata berkelanjutan dan UKM di pasar Eropa.
                </p>

                <p className="mb-4">
                  Kehadiran BCA sebagai pendukung acara ini menunjukkan komitmen
                  perseroan terhadap upaya membawa potensi dan keunggulan
                  pariwisata, serta UKM Indonesia ke kancah internasional.
                  Dukungan ini diharapkan dapat menciptakan peluang baru bagi
                  pelaku UKM, serta kian meningkatkan daya saing produk lokal di
                  pasar global.
                </p>

                <p className="mb-4">
                  BCA tidak hanya memberikan kontribusi finansial, tetapi juga
                  memperkuat sinergi antara industri keuangan, pariwisata, dan
                  UKM untuk mencapai tujuan bersama: memajukan ekonomi Indonesia
                  melalui kolaborasi lintas sektor.
                </p>

                <p className="mb-4">
                  Sinergi ini diharapkan kian memperkuat kolaborasi antar pelaku
                  usaha berbagai industri untuk mencapai visi besar membawa
                  Indonesia menjadi negara maju dan menyambut generasi emas
                  2045.
                </p>

                <p>
                  Mari bersama kita sukseskan Discovering the Magnificence of
                  Indonesia 2024 dan bawa nama Indonesia semakin dikenal luas di
                  Eropa.
                </p>
              </div>
              <div className="supporting-sponsor-article p-4 border rounded">
                <h3 className="text-2xl font-bold mb-4">About EXIMBANK</h3>
                <p className="mb-4">
                  Indonesia Eximbank (IEB) is a specialized financial
                  institution wholly owned by the Government of Indonesia with
                  sovereign status. IEB operates independently under Act No. 2
                  of 2009 to support the national export program through
                  National Export Financing (PEN).
                </p>

                <p className="mb-4">
                  With a vision to become an eminent and credible Eximbank, IEB
                  aims to boost national exports on a global scale. Its mission
                  includes, Establishing a conducive business environment to
                  achieve sustainable national export growth, Providing
                  high-quality export financing and consultation services to
                  meet Indonesia's export needs, and Assisting business players,
                  including SMEs, in producing competitive, world-class,
                  export-oriented products.
                </p>

                <p>
                  Sharing the same vision as the DMI Expo 2024, Indonesia
                  Eximbank fully supports this event, aiming to create a better
                  future and unlock great potential for Indonesia's SMEs and
                  businesses.
                </p>
              </div>
              {i18n.language !== "id" && (
                <div className="supporting-sponsor-article p-4 border rounded">
                  <h3 className="text-2xl font-bold mb-4">
                    Through DMI Expo 2024, PANDI Encourages Indonesian Tourism
                    Villages to Go Global
                  </h3>
                  <p className="mb-4">
                    Tourism serves as a gateway to the world, driving economic
                    growth, improving community welfare, and enhancing a
                    nation's image on the global stage. In line with this
                    vision, the Indonesian Internet Domain Name Registry (PANDI)
                    is proud to fully support the Discovering The Magnificence
                    of Indonesia (DMI) Expo 2024.
                  </p>

                  <p className="mb-4">
                    Tourism villages represent a model of community-based
                    tourism that empowers local communities while preserving
                    cultural heritage and promoting environmental
                    sustainability. PANDI Chairman John Sihar Simanjuntak
                    emphasized the exceptional appeal of Indonesia's tourism
                    villages, which combine breathtaking natural beauty with the
                    rich cultural heritage, making them highly attractive to
                    international tourists.
                  </p>

                  <p className="mb-4">
                    However, achieving global recognition for Indonesia's
                    tourism villages requires strong cross-sector collaboration.
                    Therefore, PANDI is participating and collaborating in the
                    DMI Expo 2024, which will be held at Jaarbeurs, Utrecht,
                    Netherlands, from November 28 to December 1, 2024.
                  </p>

                  <p className="mb-4">
                    "As we know, today's travelers are no longer content with
                    simply admiring beautiful landscapes, they also seek
                    cultural immersion and meaningful interactions with local
                    communities. This is an unique experience we can offer
                    through Indonesia's traditions, dances, music, and culinary
                    delights. These distinctive features can serve as a magnet
                    for international tourists, particularly those from Europe,
                    who are in search of authentic and unique experiences,"
                    explained PANDI Chairman John Sihar Simanjuntak.
                  </p>

                  <p>
                    To support these efforts, PANDI is also driving the
                    development of digitally integrated tourism villages by
                    promoting the creation of websites utilizing the .id domain.
                    By harnessing advancements in digital technology, these
                    tourism villages can extend their global reach and establish
                    a strong international presence. This initiative is also
                    expected to boost Indonesia's economy, not only in the
                    tourism sector but also benefiting local small and medium
                    enterprises (SME) who participates in the DMI Expo 2024.
                  </p>
                </div>
              )}
              {i18n.language === "id" && (
                <div className="supporting-sponsor-article p-4 border rounded">
                  <h3 className="text-2xl font-bold mb-4">
                    Melalui DMI Expo 2024, PANDI Dorong Desa Wisata RI Mendunia
                  </h3>
                  <p className="mb-4">
                    Pariwisata merupakan jendela dunia yang mampu mendorong
                    pertumbuhan ekonomi, kesejahteraan masyarakat dan citra
                    negara di mata dunia. Sejalan dengan visi ini, Pengelola
                    Nama Domain Internet Indonesia (PANDI) mendukung penuh acara
                    Discovering The Magnificence of Indonesia (DMI) Expo 2024
                    yang mempromosikan desa wisata Indonesia ke kancah global.
                  </p>

                  <p className="mb-4">
                    Desa wisata adalah salah satu wujud pariwisata berbasis
                    komunitas yang mampu memberdayakan masyarakat lokal
                    sekaligus menjaga kearifan lokal dan kelestarian lingkungan.
                    Ketua PANDI John Sihar Simanjuntak mengatakan keunikan desa
                    wisata Indonesia dengan keindahan alam dan kekayaan budaya
                    memiliki potensi besar dalam menarik perhatian wisatawan
                    mancanegara.
                  </p>

                  <p className="mb-4">
                    Namun untuk membawa desa wisata Indonesia mendunia
                    diperlukan kolaborasi lintas sektor, maka dari itu PANDI
                    ikut berpatisipasi berkolaborasi dalam acara DMI Expo 2024
                    yang akan digelar di Jaarbeurs, Utrecht, Belanda pada 28
                    November-1 Desember 2024.
                  </p>

                  <p className="mb-4">
                    "Seperti kita tahu, saat ini wisatawan bukan hanya ingin
                    melihat pemandangan yang indah tapi mereka juga ingin
                    mengenal budaya wilayah yang dikunjungi dan berinteraksi
                    bersama warga lokal. Peluang inilah yang bisa kita tawarkan
                    seperti tradisi, seni tari, musik, dan kuliner yang tidak
                    dapat ditemukan di tempat lain. Keunikan tersebut bisa
                    menjadi magnet bagi wisatawan internasional khususnya
                    masyarakat eropa yang mencari pengalaman otentik dan
                    berbeda," ungkap Ketua PANDI John Sihar Simanjuntak.
                  </p>

                  <p className="mb-4">
                    Selaras dengan upaya tersebut, PANDI juga mendorong
                    pengembangan desa wisata berbasis digital melalui pembuatan
                    situ web dengan menggunakan domain .id. Dengan memanfaatkan
                    kemajuan teknologi, jangkauan desa wisatawan pun secara
                    signifikan lebih luas dan diakui secara global. Inisiatif
                    ini diharapkan ikut menggeliatkan perekonomian Indonesia,
                    tak hanya pada sektor pariwisata melainkan juga usaha kecil
                    dan menengah (UKM) lokal yang terlibat dalam DMI Expo 2024.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <PartnerV2 />
          </div>
          <div className="row mt-5">
            <section className="cta-area">
              <div className="container">
                <div className="row h-100 align-items-center">
                  <div className="col-lg-8">
                    <h3>{t("sponsor.becomeSponsor.title")}</h3>
                    <span>{t("sponsor.becomeSponsor.description")}</span>
                  </div>

                  <div className="col-lg-4 text-right">
                    <Link
                      to="sponsors-application"
                      className="btn btn-secondary animated-button-dark"
                    >
                      {t("sponsor.becomeSponsor.button")}
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
      <FooterV2 />
    </React.Fragment>
  )
}

export default Sponsor
