import React from "react"
import { Link } from "react-router-dom"
import "isomorphic-fetch"
// import Footer from "../../Common/Footer";
import FooterV2 from "../../v2/layout/FooterV2"

class Contact extends React.Component {
  state = {
    submitting: false,
    submitted: false,
    buttonState: "",
    formFields: {
      name: "",
      email: "",
      phone: "",
    },
  }

  onSubmit = (e) => {
    e.preventDefault()
    const data = this.state.formFields
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
    fetch(`${apiBaseUrl}/api/sponsor`, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ submitted: true })
      }
      let formFields = Object.assign({}, this.state.formFields)
      formFields.name = ""
      formFields.email = ""
      formFields.phone = ""
      this.setState({ formFields })
    })
  }

  nameChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields)
    formFields.name = e.target.value
    this.setState({ formFields })
  }

  emailChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields)
    formFields.email = e.target.value
    this.setState({ formFields })
  }

  phoneChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields)
    formFields.phone = e.target.value
    this.setState({ formFields })
  }

  textChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields)
    formFields.message = e.target.value
    this.setState({ formFields })
  }

  onHideSuccess = () => {
    this.setState({ submitted: false })
  }

  successMessage = () => {
    if (this.state.submitted) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          style={{ marginTop: "14px" }}
        >
          <strong>Thank you for submitting your sponsor application!</strong>{" "}
          <br></br>Your application has been successfully received and we will
          review it as soon as possible. We appreciate your interest in
          sponsoring our event.
          {/* <button type="button" className="close" onClick={this.onHideSuccess}>
            <span aria-hidden="true">&times;</span>
          </button> */}
        </div>
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg1">
          <div className="container">
            <h1>Sponsor Application</h1>

            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Sponsor</li>
            </ul>
          </div>
        </div>

        <section className="contact-area ptb-120">
          <div className="container">
            <div style={{ marginLeft: "0cm", textAlign: "justify" }}>
              <img
                src={require("../../../assets/images/sp1.jpg")}
                alt="speaker"
              />
              <p
                style={{
                  color: "#0D0D0D",
                  fontFamily: "Calibri, sans-serif",
                  fontSize: "12pt",
                }}
              >
                <strong>
                  <u>SPONSOR PROFILE</u>
                </strong>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "#0D0D0D",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      Large-Medium Tour and Travel Services based in Indonesia
                    </span>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      &nbsp;
                    </span>
                  </p>
                </li>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "#0D0D0D",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      State Owned Enterprises that already have Foster Partner
                      focusing on Tourism Villages + SME Development
                    </span>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      &nbsp;
                    </span>
                  </p>
                </li>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      Fast Moving Consumer Goods who already established and
                      have expansion interests their products in Europe
                    </span>
                  </p>
                </li>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "#0D0D0D",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      Banks or Financial Institutions with initiatives
                      supporting small businesses or sustainable development
                    </span>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      &nbsp;
                    </span>
                  </p>
                </li>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "#0D0D0D",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      Media Outlets specializing in travel, sustainability, and
                      business
                    </span>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      &nbsp;
                    </span>
                  </p>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <img
                src={require("../../../assets/images/sp2.jpg")}
                alt="speaker"
              />
              <p
                style={{
                  color: "black",
                  fontFamily: "Calibri, sans-serif",
                  fontSize: "12pt",
                }}
              >
                <strong>
                  <u>WHY SPONSOR US</u>
                </strong>
              </p>
              <ul>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      <strong>
                        Tourism Promotion<u>&nbsp;</u>
                      </strong>
                    </span>
                  </p>
                </li>
              </ul>
              <p style={{ marginLeft: "36pt", textAlign: "justify" }}>
                <span
                  style={{
                    color: "#0D0D0D",
                    fontFamily: "Calibri, sans-serif",
                    fontSize: "12pt",
                  }}
                >
                  Contribute to boosting tourism in Indonesia while aligning
                  with the growing trend of responsible travel in Europe
                </span>
              </p>
              <ul>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      <strong>Business Opportunities</strong>
                      <u>&nbsp;</u>
                    </span>
                  </p>
                </li>
              </ul>
              <p style={{ marginLeft: "36pt", textAlign: "justify" }}>
                <span
                  style={{
                    color: "#0D0D0D",
                    fontFamily: "Calibri, sans-serif",
                    fontSize: "12pt",
                  }}
                >
                  Opportunities for investment or collaboration from European
                  with Indonesian SMEs
                </span>
              </p>
              <ul>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      <strong>
                        Cultural Exchange<u>&nbsp;</u>
                      </strong>
                    </span>
                  </p>
                </li>
              </ul>
              <p style={{ marginLeft: "36pt", textAlign: "justify" }}>
                <span
                  style={{
                    color: "#0D0D0D",
                    fontFamily: "Calibri, sans-serif",
                    fontSize: "12pt",
                  }}
                >
                  It provides an opportunity for cultural exchange between
                  Indonesia and Europe, fostering understanding and appreciation
                  of each other's heritage and traditions
                </span>
              </p>
              <ul>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      <strong>Environmental Awareness</strong>
                      <u>&nbsp;</u>
                    </span>
                  </p>
                </li>
              </ul>
              <p style={{ marginLeft: "36pt", textAlign: "justify" }}>
                <span
                  style={{
                    color: "#0D0D0D",
                    fontFamily: "Calibri, sans-serif",
                    fontSize: "12pt",
                  }}
                >
                  Through Forum, Business Meeting, and Exhibitions, this expo
                  can raise awareness about the importance of preserving natural
                  resources and promoting eco-friendly practices in the tourism
                  industry
                </span>
              </p>
              <ul>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      <strong>Networking</strong>
                      <u>&nbsp;</u>
                    </span>
                  </p>
                </li>
              </ul>
              <p style={{ marginLeft: "36pt", textAlign: "justify" }}>
                <span
                  style={{
                    color: "#0D0D0D",
                    fontFamily: "Calibri, sans-serif",
                    fontSize: "12pt",
                  }}
                >
                  This networking can lead to knowledge sharing, collaborations,
                  and future business opportunities
                </span>
              </p>
              <ul>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      <strong>Brand Exposure</strong>
                      <u>&nbsp;</u>
                    </span>
                  </p>
                </li>
              </ul>
              <p style={{ marginLeft: "36pt", textAlign: "justify" }}>
                <span
                  style={{
                    color: "#0D0D0D",
                    fontFamily: "Calibri, sans-serif",
                    fontSize: "12pt",
                  }}
                >
                  This exposure can enhance your brand's reputation, especially
                  if it's associated with sustainability and cultural
                  appreciation
                </span>
              </p>
              <ul>
                <li>
                  <p style={{ textAlign: "justify" }}>
                    <span
                      style={{
                        color: "black",
                        fontFamily: "Calibri, sans-serif",
                        fontSize: "12pt",
                      }}
                    >
                      <strong>Community Engagement</strong>
                      <u>&nbsp;</u>
                    </span>
                  </p>
                </li>
              </ul>
              <p style={{ marginLeft: "36pt", textAlign: "justify" }}>
                <span
                  style={{
                    color: "#0D0D0D",
                    fontFamily: "Calibri, sans-serif",
                    fontSize: "12pt",
                  }}
                >
                  Supporting initiatives that promote green tourism and SME
                  development demonstrates corporate social responsibility and a
                  commitment to supporting local communities, both in Indonesia
                  and abroad
                </span>
                <span
                  style={{
                    color: "black",
                    fontFamily: "Calibri, sans-serif",
                    fontSize: "12pt",
                  }}
                >
                  <u>&nbsp;</u>
                </span>
              </p>
              <p>&nbsp;</p>
              <p style={{ textAlign: "justify" }}>
                <span
                  style={{
                    color: "black",
                    fontFamily: "Calibri, sans-serif",
                    fontSize: "12pt",
                  }}
                >
                  Overall, sponsoring the "Discovering The Magnificence of
                  Indonesia Expo" with a focus on promoting green tourism
                  villages and small to medium enterprises (SMEs) in Europe,
                  specifically in Utrecht, Netherlands, could offer aligns with
                  the goals of promoting sustainable tourism, fostering cultural
                  exchange, and creating business opportunities between
                  Indonesia and Europe, while also enhancing your brand's
                  visibility and reputation.
                </span>
              </p>
            </div>

            {/* <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-phone"></i>
                  </div>

                  <div className="content">
                    <h4>Phone </h4>
                    <p>+31 (0)643 174984</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-email"></i>
                  </div>

                  <div className="content">
                    <h4>E-mail</h4>
                    <p>info@negeriku.nl</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-world"></i>
                  </div>

                  <div className="content">
                    <h4>Location</h4>
                    <p>Hengevelderweg 1, 7478PE Diepenheim, Netherlands</p>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div
              dangerouslySetInnerHTML={{
                __html: `<p style="margin-left:0cm;text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;"><strong><u>SPONSOR PROFILE</u></strong></span></p>
<p style="margin-left:0cm;text-align:justify;">&nbsp;</p>
<ul>
    <li>
        <p style="text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;">Large-Medium Tour and Travel Services based in Indonesia</span><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;">&nbsp;</span></p>
    </li>
    <li>
        <p style="text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;">State Owned Enterprises that already have Foster Partner focusing on Tourism Villages + SME Development</span><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;">&nbsp;</span></p>
    </li>
    <li>
        <p style="text-align:justify;"><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;">Fast Moving Consumer Goods who already established and have expansion interests their products in Europe</span></p>
    </li>
    <li>
        <p style="text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;">Banks or Financial Institutions with initiatives supporting small businesses or sustainable development</span><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;">&nbsp;</span></p>
    </li>
    <li>
        <p style="text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;">Media Outlets specializing in travel, sustainability, and business</span><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;">&nbsp;</span></p>
    </li>
</ul>
<p style="margin-left:0cm;text-align:justify;">&nbsp;</p>
<p style="margin-left:0cm;text-align:justify;">&nbsp;</p>
<p style="margin-left:0cm;text-align:justify;">&nbsp;</p>
<p style="margin-left:0cm;text-align:justify;"><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;"><strong><u>WHY SPONSOR US</u></strong></span></p>
<ul>
    <li>
        <p style="text-align:justify;"><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;"><strong>Tourism Promotion<u>&nbsp;</u></strong></span></p>
    </li>
</ul>
<p style="margin-left:36pt;text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;">Contribute to boosting tourism in Indonesia while aligning with the growing trend of responsible travel in Europe</span></p>
<ul>
    <li>
        <p style="text-align:justify;"><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;"><strong>Business Opportunities</strong><u>&nbsp;</u></span></p>
    </li>
</ul>
<p style="margin-left:36pt;text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;">Opportunities for investment or collaboration from European with Indonesian SMEs</span></p>
<ul>
    <li>
        <p style="text-align:justify;"><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;"><strong>Cultural Exchange<u>&nbsp;</u></strong></span></p>
    </li>
</ul>
<p style="margin-left:36pt;text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;">It provides an opportunity for cultural exchange between Indonesia and Europe, fostering understanding and appreciation of each other's heritage and traditions</span></p>
<ul>
    <li>
        <p style="text-align:justify;"><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;"><strong>Environmental Awareness</strong><u>&nbsp;</u></span></p>
    </li>
</ul>
<p style="margin-left:36pt;text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;">Through Forum, Business Meeting, and Exhibitions, this expo can raise awareness about the importance of preserving natural resources and promoting eco-friendly practices in the tourism industry</span></p>
<ul>
    <li>
        <p style="text-align:justify;"><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;"><strong>Networking</strong><u>&nbsp;</u></span></p>
    </li>
</ul>
<p style="margin-left:36pt;text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;">This networking can lead to knowledge sharing, collaborations, and future business opportunities</span></p>
<ul>
    <li>
        <p style="text-align:justify;"><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;"><strong>Brand Exposure</strong><u>&nbsp;</u></span></p>
    </li>
</ul>
<p style="margin-left:36pt;text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;">This exposure can enhance your brand's reputation, especially if it's associated with sustainability and cultural appreciation</span></p>
<ul>
    <li>
        <p style="text-align:justify;"><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;"><strong>Community Engagement</strong><u>&nbsp;</u></span></p>
    </li>
</ul>
<p style="margin-left:36pt;text-align:justify;"><span style="color:#0D0D0D;font-family:Calibri, sans-serif;font-size:12pt;">Supporting initiatives that promote green tourism and SME development demonstrates corporate social responsibility and a commitment to supporting local communities, both in Indonesia and abroad</span><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;"><u>&nbsp;</u></span></p>
<p style="margin-left:0cm;text-align:justify;">&nbsp;</p>
<p style="margin-left:0cm;text-align:justify;"><span style="color:black;font-family:Calibri, sans-serif;font-size:12pt;">Overall, sponsoring the "Discovering The Magnificence of Indonesia Expo" with a focus on promoting green tourism villages and small to medium enterprises (SMEs) in Europe, specifically in Utrecht, Netherlands, could offer aligns with the goals of promoting sustainable tourism, fostering cultural exchange, and creating business opportunities between Indonesia and Europe, while also enhancing your brand's visibility and reputation.</span>`,
              }}
            ></div> */}
            <div className="row h-100 align-items-center contact-form">
              {/* <div className="col-lg-4 col-md-12">
                <div className="leave-your-message">
                  <h3>Leave Your Message</h3>
                  <p>
                    If you have any questions about the services we provide
                    simply use the form below. We try and respond to all queries
                    and comments within 24 hours.
                  </p>

                  <div className="stay-connected">
                    <h3>Stay Connected</h3>
                    <ul>
                      <li>
                        <Link to="https://negeriku.nl/" target="_blank">
                          <i className="icofont-globe"></i>
                          <span>Website</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}

              <div className="col-lg-12 col-md-12">
                <form onSubmit={this.onSubmit} id="contactForm">
                  <div className="row">
                    <div id="msgSubmit" className="h3 text-center hidden">
                      {this.successMessage()}
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="name">Institution Name*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          required={true}
                          data-error="Please enter your name"
                          value={this.state.formFields.name}
                          onChange={this.nameChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="email">Email*</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          required={true}
                          data-error="Please enter your email"
                          value={this.state.formFields.email}
                          onChange={this.emailChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="number">Phone Number*</label>
                        <input
                          type="text"
                          className="form-control"
                          name="number"
                          id="number"
                          required={true}
                          data-error="Please enter your number"
                          value={this.state.formFields.phone}
                          onChange={this.phoneChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    {/* <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="message">Message*</label>
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          cols="30"
                          rows="4"
                          required={true}
                          data-error="Write your message"
                          value={this.state.formFields.message}
                          onChange={this.textChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div> */}

                    <div className="col-lg-12 col-md-12">
                      <button
                        type="submit"
                        className="btn btn-primary animated-button"
                      >
                        Send Application
                      </button>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    )
  }
}

export default Contact
