import "./assets/css/bootstrap.min.css"
import "./assets/css/icofont.min.css"
import "./assets/css/animate.min.css"
import "../node_modules/react-modal-video/css/modal-video.min.css"
import "./assets/css/style.css"
import "./assets/css/responsive.css"

import React, { useEffect } from "react"
import AppRouter from "./Routes"
import Preloader from "./components/Shared/Preloader"

class App extends React.Component {
  state = {
    loading: true,
  }

  componentDidMount() {
    // Load Google Analytics
    const script1 = document.createElement("script")
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-07YELZCLH8"
    script1.async = true
    document.head.appendChild(script1)

    const script2 = document.createElement("script")
    script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-07YELZCLH8');
        `
    document.head.appendChild(script2)

    this.demoAsyncCall().then(() => this.setState({ loading: false }))
  }

  demoAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000))
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading ? <Preloader /> : ""}
        <AppRouter />
      </React.Fragment>
    )
  }
}

export default App
