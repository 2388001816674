import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// import Footer from "../Common/Footer";
import { withTranslation } from "react-i18next";
import FooterV2 from "../v2/layout/FooterV2";

class News extends React.Component {
  state = {
    posts: [],
    currentPage: 1,
    totalPages: 1,
    error: null,
  };

  componentDidMount() {
    this.fetchBlogPosts(this.state.currentPage);
  }

  async fetchBlogPosts(page) {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.get(`${apiBaseUrl}/api/news?page=${page}`);
      const { data, current_page, last_page } = response.data.data;

      this.setState({
        posts: data,
        currentPage: current_page,
        totalPages: last_page,
        error: null,
      });
    } catch (error) {
      console.error("Error fetching blog posts:", error);
      this.setState({
        error: this.props.t("news.errors.fetchError"),
      });
    }
  }

  formatDate = (dateString) => {
    const { i18n } = this.props;
    const date = new Date(dateString);
    return date.toLocaleDateString(i18n.language, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  render() {
    const { posts, currentPage, totalPages, error } = this.state;
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="page-title-area item-bg1">
          <div className="container">
            <h1>{t("news.title")}</h1>
            <span>{t("news.subtitle")}</span>
            <ul>
              <li>
                <Link to="/">{t("news.breadcrumb.home")}</Link>
              </li>
              <li>{t("news.breadcrumb.news")}</li>
            </ul>
          </div>
        </div>

        <section className="blog-area ptb-120 bg-image">
          <div className="container">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            <div className="row">
              {posts.map((post) => (
                <div className="col-lg-4 col-md-6" key={post.id}>
                  <div className="single-blog-post">
                    <div className="blog-image">
                      <Link to={`news/${post.slug}`}>
                        <img src={post.image} alt={post.title} />
                      </Link>

                      <div className="post-tag">
                        <Link to={`news/${post.slug}`}>
                          {post.category.charAt(0).toUpperCase() +
                            post.category.slice(1)}
                        </Link>
                      </div>
                    </div>

                    <div className="blog-post-content">
                      <span className="date">
                        {this.formatDate(post.created_at)}
                      </span>
                      <h3>
                        <Link to={`news/${post.slug}`}>{post.title}</Link>
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.content.slice(0, 300),
                        }}
                      />
                      <Link to={`news/${post.slug}`} className="read-more-btn">
                        {t("news.readMore")}{" "}
                        <i className="icofont-double-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-lg-12 col-md-12">
                <div className="pagination-area">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            if (currentPage !== 1) {
                              this.fetchBlogPosts(currentPage - 1);
                            }
                          }}
                          aria-label={t("news.pagination.previous")}
                        >
                          <i className="icofont-double-left"></i>
                        </Link>
                      </li>

                      {Array.from({ length: totalPages }, (_, index) => (
                        <li
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                          key={index}
                        >
                          <Link
                            className="page-link"
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.fetchBlogPosts(index + 1);
                            }}
                            aria-label={`${t("news.pagination.page")} ${
                              index + 1
                            }`}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      ))}

                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            if (currentPage !== totalPages) {
                              this.fetchBlogPosts(currentPage + 1);
                            }
                          }}
                          aria-label={t("news.pagination.next")}
                        >
                          <i className="icofont-double-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    );
  }
}

export default withTranslation()(News);
