import CompassIcon from "./CompassIcon";
import CalendarIcon from "./CalendarIcon";
import "./PlaceAndDate.css";

const PlaceAndDate = () => {
  return (
    <ul className="place-date-list">
      <li className="place-date-item">
        <CompassIcon />
        Jaarbeurs, Utrecht - Netherlands
      </li>
      <li className="place-date-item">
        <CalendarIcon />
        30<sup>th</sup> October 2025 - 2<sup>nd</sup> November 2025
      </li>
    </ul>
  );
};

export default PlaceAndDate;
