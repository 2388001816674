import React from "react"
import { Link } from "react-router-dom"
import { Star } from "lucide-react"
import LaxDiv from "../Shared/LaxDiv"
import { withTranslation } from "react-i18next"

const hotelData = [
  // {
  //   id: 1,
  //   name: "Hampton by Hilton Utrecht Central Station",
  //   image:
  //     "https://www.hilton.com/im/en/AMSUNHX/10615164/exterior-1-.jpg?impolicy=crop&cw=5760&ch=3225&gravity=NorthWest&xposition=0&yposition=307&rw=768&rh=430",
  //   stars: 3,
  //   price: "Rp 3.062.156",
  //   distance: "550 m (on foot) - 1,6 km (by car)",
  //   mapLink: "https://maps.app.goo.gl/cfFpjEa8nUSNUxF56",
  // },
  {
    id: 2,
    name: "Holiday Inn Express Utrecht - Papendorp, an IHG Hotel",
    image:
      "https://digital.ihg.com/is/image/ihg/holiday-inn-express-utrecht-8806299615-4x3?wid=733",
    stars: 3,
    price: "Rp 2.292.362",
    distance: "- 3,7 - 4,5 km (by car)",
    mapLink: "https://maps.app.goo.gl/qYdZNYGXncmreLry9",
  },
  {
    id: 3,
    name: "Ibis Utrecht",
    image:
      "https://cdn.ibis-hotel-utrecht.nl/wp-content/uploads/2019/01/ibis-Utrecht-Exterior-AMP.jpg",
    stars: 3,
    price: "Rp 2.036.493 - Rp 2.652.575",
    distance: "- 1,3 - 1,4 km (on foot) <br>- 1,4 - 2,2 km (by car)",
    mapLink: "https://maps.app.goo.gl/14LgWPo1EheY3uxZA",
  },
  // {
  //   id: 4,
  //   name: "Hotel NH Utrecht",
  //   image:
  //     "https://img.nh-hotels.net/Ye3r/n0ekz/original/RSP_NH_utrecht_068.jpg?output-quality=70&resize=555:*&composite-to=center,center|555:280&background-color=white",
  //   stars: 4,
  //   price: "Rp 2.036.493 - Rp 3.453.143",
  //   distance: "- 230 m (on foot) - 450 m (by car)",
  //   mapLink: "https://maps.app.goo.gl/DwaPbQLVNss9pNyp6",
  // },
  // {
  //   id: 5,
  //   name: "Park Plaza Utrecht",
  //   image:
  //     "https://media.radissonhotels.net/image/park-plaza-utrecht/guest-room/16256-114952-f70951226_4k.jpg?impolicy=GalleryLightboxFullscreen",
  //   stars: 4,
  //   price: "Rp 2.259.154 - Rp 3.146.860",
  //   distance: "- 400 - 500 m (on foot) - 650 m (by car)",
  //   mapLink: "https://maps.app.goo.gl/ahSADxrYfi8MTion7",
  // },
  {
    id: 6,
    name: "Inntel Hotels Utrecht Centre",
    image:
      "https://www.inntelhotelsutrechtcentre.nl/wp-content/uploads/sites/5/2018/11/Spa-Corner-Angle-2-1.jpg",
    stars: 4,
    price: "Rp 2.052.746 - Rp 4.755.482",
    distance: "- 500 - 800 m (on foot) <br>- 1,4 km (by car)",
    mapLink: "https://maps.app.goo.gl/BnWvVwfWjj2SvgBn6",
  },
  {
    id: 7,
    name: "The Anthony Hotel Utrecht",
    image:
      "https://theanthony.nl/wp-content/uploads/2021/05/TheAnthony-GardenYard-1.jpg",
    stars: 4,
    price: "Rp 3.780.686 - Rp 5.199.706",
    distance: "- 600 m (on foot) <br>- 1,1 km (by car)",
    mapLink: "https://maps.app.goo.gl/3fXe9XFy59sdDHsh9",
  },
  {
    id: 8,
    name: "Crowne Plaza Utrecht - Central Station, an IHG Hotel",
    image:
      "https://digital.ihg.com/is/image/ihg/crowne-plaza-utrecht-6592366929-3x2?wid=733",
    stars: 5,
    price: "Rp 2.753.948 - Rp 3.814.474",
    distance: "- 600 m (on foot) <br>- 1,8 km (by car)",
    mapLink: "https://maps.app.goo.gl/GxhUAmx2jvFVUbUQ8",
  },
  {
    id: 9,
    name: "Grand Hotel Karel V",
    image: "https://www.karelv.nl/wp-content/uploads/2021/11/drone.jpg",
    stars: 5,
    price: "Rp 3.592.034 - Rp 4.807.117",
    distance: "- 1 - 1.5 km (on foot) <br>- 2,7 - 3,6 km (by car)",
    mapLink: "https://maps.app.goo.gl/Kb2oYyd8Dh8DCFru9",
  },
  // Add more hotel objects here
]

class Hotels extends React.Component {
  renderStars(count) {
    return Array(count)
      .fill()
      .map((_, i) => <Star key={i} size={16} fill="gold" color="gold" />)
  }

  renderHotel(hotel) {
    const { t } = this.props
    return (
      <div className="col-lg-4 col-md-6" key={hotel.id}>
        <div className="single-speakers-box">
          <div className="speakers-image">
            <div className="image-container">
              <img src={hotel.image} alt={hotel.name} />
            </div>
          </div>

          <div className="speakers-content">
            <h3>
              <a href={hotel.mapLink} target="_blank" rel="noopener noreferrer">
                {hotel.name}
              </a>
            </h3>
            <div className="stars">{this.renderStars(hotel.stars)}</div>
            <br />
            <span className="price">
              {t("hotels.priceStart")} <b>{hotel.price}</b>
            </span>
            <span
              className="distance"
              dangerouslySetInnerHTML={{ __html: hotel.distance }}
            ></span>

            <ul className="social">
              <li>
                <a
                  href={hotel.mapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icofont-link"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t } = this.props
    return (
      <section className="speakers-area-two ptb-120">
        <div className="container">
          <div className="section-title">
            <span>{t("hotels.title")}</span>
            <h2>
              {t("hotels.subtitle")} <b>{t("hotels.subtitleElement")}</b> <br />
            </h2>

            <LaxDiv text={t("hotels.title")} dataPreset="driftLeft" />

            <div className="bar"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="btn-box">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="single-speakers-box">
                <div className="speakers-image">
                  <div className="image-container">
                    <img
                      src={`https://res.cloudinary.com/dvnpm2hid/image/upload/v1728567332/dmiexpo/mbnzf9qxt3lypctrps1z.png`}
                      alt={`Special Price`}
                    />
                  </div>
                </div>

                <div className="speakers-content">
                  <h3>
                    <a
                      href={`https://lennonservices.nl/congress/module/32ad8ec3-0faa-4081-b0c2-840162540c70?thema_id=27`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PARTNER HOTEL WITH SPECIAL PRICE
                    </a>
                  </h3>
                  <span className="distance">
                    Include City Tax <br></br>Free Shuttle
                  </span>
                  {/* <div className="stars">{this.renderStars(5)}</div> */}

                  <ul className="social">
                    <li>
                      <a
                        href={`https://lennonservices.nl/congress/module/32ad8ec3-0faa-4081-b0c2-840162540c70?thema_id=27`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {hotelData.map((hotel) => this.renderHotel(hotel))}
          </div>
        </div>
        <style jsx>{`
          .image-container {
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio */
            position: relative;
            overflow: hidden;
          }
          .image-container img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .btn-box {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
      </section>
    )
  }
}

export default withTranslation()(Hotels)
