import OwlCarousel from "react-owl-carousel3";
import "./SponsorCarousel.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useState } from "react";

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  margin: 10,
  // center: true,
  autoWidth: true,
};

const SponsorCarousel = ({ data, title }) => {
  const nonMobileOptions = {
    responsive: {
      1024: {
        loop: data.length >= 4, // Set loop conditionally
      },
    },
  };

  return (
    <div className="sponsor-carousel-container">
      <h3>{title}</h3>

      <div className="sponsor-carousel-border"></div>
      <OwlCarousel
        className="gold-partner-slides owl-carousel owl-theme"
        {...options}
        {...nonMobileOptions}
      >
        {data.map((sponsor) => (
          <a
            href={sponsor.link}
            key={sponsor.id}
            target="_blank"
            rel="noopener noreferrer"
            className="sponsor-carousel-image-card item"
          >
            <img src={sponsor.image} alt={`${sponsor.name} Logo`} />
          </a>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default SponsorCarousel;
