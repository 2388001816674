import { useState, useEffect } from "react"
import lax from "lax.js"
import { useTranslation } from "react-i18next"
import "./HomeAbout.css"
import { Link } from "react-router-dom"
import ImageOne from "../../../../assets/images/carousel/about-1.png"
import ImageTwo from "../../../../assets/images/carousel/about-2.png"
import ImageThree from "../../../../assets/images/carousel/about-3.png"
import ImageFour from "../../../../assets/images/carousel/about-4.png"
import GalleryOne from "../../../../assets/images/carousel/gallery-1.png"
import GalleryTwo from "../../../../assets/images/carousel/gallery-2.png"
import GalleryThree from "../../../../assets/images/carousel/gallery-3.png"
import GalleryFour from "../../../../assets/images/carousel/gallery-4.png"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/autoplay"
import MyButton from "../../../ui/MyButton"

const HomeAbout = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [showMore, setShowMore] = useState(false)

  const { t } = useTranslation()

  const toggleShowMore = () => {
    setShowMore((prev) => !prev)
  }

  useEffect(() => {
    // Setup lax
    lax.setup()

    // Initial update
    lax.update(window.scrollY)

    // Scroll event listener
    const handleScroll = () => {
      lax.update(window.scrollY)
    }

    // Add scroll listener
    document.addEventListener("scroll", handleScroll, false)

    // Optional: Add window resize listener to update mobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener("resize", handleResize)

    // Cleanup function
    return () => {
      document.removeEventListener("scroll", handleScroll)
      window.removeEventListener("resize", handleResize)
    }
  }, []) // Empty dependency array means this runs once on mount

  return (
    <section>
      <div className="container">
        <div className="home-about-description">
          <h1>{t("about.joinEvent")}</h1>
          <h2>{t("about.title")}</h2>
          <p>{t("about.description.part1")}</p>
          {isMobile && !showMore && (
            <MyButton onClick={toggleShowMore}>
              {t("homePage.readMore")}
            </MyButton>
          )}

          {isMobile && showMore && (
            <>
              <p>{t("about.description.part2")}</p>
              <p>{t("about.description.part3")}</p>
              <MyButton onClick={toggleShowMore}>
                {t("homePage.readLess")}
              </MyButton>
            </>
          )}
          {!isMobile && (
            <>
              <p>{t("about.description.part2")}</p>
              <p>{t("about.description.part3")}</p>
            </>
          )}
        </div>
      </div>

      <div className="home-about-swiper-container">
        <Swiper
          className="home-about-swiper"
          spaceBetween={isMobile ? 5 : 32}
          slidesPerView={"auto"}
          freeMode={true}
        >
          <SwiperSlide>
            <div></div>
          </SwiperSlide>

          <SwiperSlide>
            <div>
              <img src={ImageOne} alt="slideshow 1" />
              <p>Herb and Spices</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <img src={ImageTwo} alt="slideshow 2" />
              <p>Fashion and Accessories</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <img src={ImageThree} alt="slideshow 3" />
              <p>Textiles and Handicraft</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <img src={ImageFour} alt="slideshow 4" />
              <p>Food and Beverages</p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div></div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="container">
        <div className="gallery-layout">
          <h2>{t("about.gallery")}</h2>
          <Link to={"gallery"}>
            {t("homePage.seeMore")}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={isMobile ? "16" : "24"}
              height={isMobile ? "16" : "24"}
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000000"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l14 0" />
              <path d="M13 18l6 -6" />
              <path d="M13 6l6 6" />
            </svg>
          </Link>
        </div>
      </div>

      <div className="home-about-swiper-container">
        <Swiper
          className="home-about-swiper"
          spaceBetween={isMobile ? 5 : 32}
          slidesPerView={"auto"}
          freeMode={true}
        >
          <SwiperSlide>
            <div></div>
          </SwiperSlide>

          <SwiperSlide>
            <img src={GalleryOne} alt="slideshow 1" />
          </SwiperSlide>
          {/* <SwiperSlide>
            <img src={GalleryTwo} alt="slideshow 2" />
          </SwiperSlide> */}
          <SwiperSlide>
            <img src={GalleryThree} alt="slideshow 3" />
          </SwiperSlide>
          {/* <SwiperSlide>
            <img src={GalleryFour} alt="slideshow 4" />
          </SwiperSlide> */}

          <SwiperSlide>
            <div></div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="home-yt-gallery">
        <div className="container">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/C14XbwzNnK4"
            title={t("about.video")}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  )
}

export default HomeAbout
