import React from "react";
import MainBanner from "../HomeDefault/MainBanner";
// import About from "../HomeDefault/About";
// import WhyUs from "../Common/WhyUs"
// import Speakers from "../HomeDefault/Speakers"
import GoTop from "../Shared/GoTop";
// import Footer from "../Common/Footer";
// import EventSchedules from "../HomeDefault/EventSchedules"
// import FunFact from "../Common/FunFact"
// import Pricing from "../HomeDefault/Pricing"
import lax from "lax.js";
// import Partner from "../Common/Partner";
// import LatestNews from "../HomeDefault/LatestNews";
// import Hotels from "../HomeDefault/Hotels";
// import BuyTicket from "../Common/BuyTicket";
import HomeAbout from "../v2/home-default/home-about-module/HomeAbout";
import PartnerV2 from "../v2/common/PartnerV2";
import LatestNewsV2 from "../v2/home-default/latest-news-module/LatestNewsV2";
import HomeHotels from "../v2/home-default/home-hotels-module/HomeHotels";
import BuyTicketV2 from "../v2/common/BuyTicketV2";
import FooterV2 from "../v2/layout/FooterV2";
// import Subscribe from "../Common/Subscribe"

class HomeDefault extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }
  render() {
    return (
      <React.Fragment>
        {/* Main Banner */}
        <MainBanner />
        {/* About Area */}
        {/* <About /> */}
        <HomeAbout />
        {/* Why Choose Us Area */}
        {/* <WhyUs /> */}
        {/* Speakers Area */}
        {/* <Speakers /> */}
        {/* Schedule Area */}
        {/* <EventSchedules /> */}
        {/* FunFacts Area */}
        {/* <FunFact /> */}
        {/* Pricing Area */}
        {/* <Pricing /> */}

        {/* Partner Area */}
        {/* V1 */}
        {/* <Partner /> */}
        {/* V2 */}
        <PartnerV2 />

        {/* Blog Area */}
        {/* V1 */}
        {/* <LatestNews /> */}
        {/* V2 */}
        <LatestNewsV2 />

        {/* V1 */}
        {/* <Hotels /> */}
        {/* V2 */}
        <HomeHotels />

        {/* Buy Tickets Area */}
        {/* V1 */}
        {/* <BuyTicket /> */}
        {/* V2 */}
        <BuyTicketV2 />

        {/* Subscribe Area */}
        {/* <Subscribe /> */}

        {/* <Footer /> */}
        <FooterV2 />

        {/* Back Top top */}
        <GoTop scrollStepInPx="50" delayInMs="16.66" />
      </React.Fragment>
    );
  }
}

export default HomeDefault;
