import { Link } from "react-router-dom";
import "./BecomeASponsorBtn.css";

const BecomeASponsorBtn = ({ link, text, ...props }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <button className="sponsor-btn" {...props} onClick={() => handleClick()}>
      <Link to={link}>
        <Icon />
        {text}
      </Link>
    </button>
  );
};

export default BecomeASponsorBtn;

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em" // Set width to em to be responsive
      height="1.5em" // Set height to em to be responsive
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M5 12l14 0" />
      <path d="M13 18l6 -6" />
      <path d="M13 6l6 6" />
    </svg>
  );
};
