import "./EventCountDownTimer.css";

const EventCountDownTimer = ({ dayTime, hourTime, minuteTime, secondTime }) => {
  return (
    <div className="event-countdown-timer">
      <div id="timer">
        <div id="days" className="event-countdown-timer-child">
          <div>{dayTime}</div>
          <span>Days</span>
        </div>
        <div id="hours" className="event-countdown-timer-child">
          <div>{hourTime}</div> <span>Hours</span>
        </div>
        <div id="minutes" className="event-countdown-timer-child">
          <div>{minuteTime}</div> <span>Minutes</span>
        </div>
        <div id="seconds" className="event-countdown-timer-child">
          <div>{secondTime}</div> <span>Seconds</span>
        </div>
      </div>
    </div>
  );
};

export default EventCountDownTimer;
