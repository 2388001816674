import React from "react"
import lax from "lax.js"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
const containerStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  height: "480px",
  // margin top 10vh
  marginTop: "10vh",
}

const containerStyleMobile = {
  display: "grid",
  gridTemplateRows: "1fr 1fr",
  // margin top 10vh
  marginTop: "10vh",
}

const columnStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
}

const imageGridStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridGap: "10px",
}

const imageItemStyle = {
  width: "100%",
  height: "auto",
}
class About extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()
    this.state = {
      isMobile: window.innerWidth <= 768,
    }
    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }

  render() {
    const { t } = this.props

    return (
      <section className="about-area ptb-120 bg-image">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6">
              <div className="about-content">
                <span>{t("about.joinEvent")}</span>
                <h2>{t("about.title")}</h2>
                <p>{t("about.description.part1")}</p>
                <p>{t("about.description.part2")}</p>
                <p>{t("about.description.part3")}</p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="image-container">
                <img
                  src={require("../../assets/images/about1.jpg")}
                  className="about-img1"
                  alt={t("about.imageAlt.about")}
                />
              </div>
              <div>
                <img
                  src={require("../../assets/images/umkm.jpg")}
                  className="about-img3"
                  alt={t("about.imageAlt.about")}
                />
              </div>
            </div>
          </div>

          <div
            style={this.state.isMobile ? containerStyleMobile : containerStyle}
          >
            <Link to={"gallery"}>
              <h2>{t("about.gallery")}</h2>
              <div style={imageGridStyle}>
                <img
                  src="https://res.cloudinary.com/dvnpm2hid/image/upload/f_auto,q_auto/v1/dmiexpo/mzrcknlt3yzpjfftl8sh"
                  alt={t("about.imageAlt.image1")}
                  style={imageItemStyle}
                />
                <img
                  src="https://res.cloudinary.com/dvnpm2hid/image/upload/f_auto,q_auto/v1/dmiexpo/yghdvfygaia8uesgfask"
                  alt={t("about.imageAlt.image2")}
                  style={imageItemStyle}
                />
                <img
                  src="https://res.cloudinary.com/dvnpm2hid/image/upload/f_auto,q_auto/v1/dmiexpo/ld2hxgzo5f3hdm4soad0"
                  alt={t("about.imageAlt.image3")}
                  style={imageItemStyle}
                />
                <img
                  src="https://res.cloudinary.com/dvnpm2hid/image/upload/f_auto,q_auto/v1/dmiexpo/dfyxehorwi9z4em7tc0j"
                  alt={t("about.imageAlt.image4")}
                  style={imageItemStyle}
                />
              </div>
            </Link>
            <div style={columnStyle}>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/C14XbwzNnK4"
                title={t("about.video")}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation()(About)
