import React from "react"
import { Link } from "react-router-dom"
import { Star } from "lucide-react"
import LaxDiv from "../Shared/LaxDiv"
import { withTranslation } from "react-i18next"

const transportationData = [
  {
    id: 1,
    name: "Station Utrecht Centraal",
    type: "Stasiun Kereta",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR42iAqo_2IWLc9pHtaRwew6g5pfEWNQqqlJg&s",
    address: "Stationshal 305, 3511 CE Utrecht, Netherlands",
    distance: "- 270 m (by car) <br> - 350 m (on foot)",
    mapLink: "https://maps.app.goo.gl/BSA93aEucqno7LUm9",
  },
  {
    id: 2,
    name: "Utrecht Centraal Jaarbeurszijde",
    type: "Halte Bus",
    image: "https://live.staticflickr.com/5142/5646584761_beb1e48eb3_b.jpg",
    address: "Utrecht Centraal Jaarbeurszijde, 3521 AG Utrecht, Netherlands",
    distance: "- 140 m (by car) <br> - 500 - 600 m (on foot)",
    mapLink: "https://maps.app.goo.gl/F9jP1AAyQ9NoLgfr6",
  },
  {
    id: 3,
    name: "Hilversum airport",
    type: "Bandara",
    image:
      "https://c8.alamy.com/comp/C567T2/the-netherlands-hilversum-airport-C567T2.jpg",
    address: "Noodweg 49, 1213 PW Hilversum, Netherlands",
    distance: "- 24.7 km (by car)",
    mapLink: "https://maps.app.goo.gl/2VfjtjvTco87XyG79",
  },
  {
    id: 4,
    name: "Amsterdam Airport Schiphol",
    type: "Bandara",
    image:
      "https://www.mageba-group.com/de/data/docs/en/5631/Refsheet-Schiphol-Airport-mainimg.jpg?v=1.0",
    address: "Aankomstpassage 1, 1118 AX Schiphol, Netherlands",
    distance: "- 45.0 km (by car)",
    mapLink: "https://maps.app.goo.gl/4e2GYUNpjvS6xW1Z9",
  },
  {
    id: 5,
    name: "Utrecht, CS Jaarbeursplein (H)",
    type: "Tram Stop",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/e/e5/Sneltramhalte_Jaarbeursplein.JPG",
    address: "3521 CA Utrecht, Netherlands",
    distance: "- 1,2 km (by car) <br> - 500 - 650 m (on foot)",
    mapLink: "https://maps.app.goo.gl/fQpVyMBdmQ3BLrm56",
  },
  // Add more transportation objects here
]

class Transportations extends React.Component {
  renderStars(count) {
    return Array(count)
      .fill()
      .map((_, i) => <Star key={i} size={16} fill="gold" color="gold" />)
  }

  renderTransportation(transportation) {
    return (
      <div className="col-lg-4 col-md-6" key={transportation.id}>
        <div className="single-speakers-box">
          <div className="speakers-image">
            <div className="image-container">
              <img src={transportation.image} alt={transportation.name} />
            </div>
          </div>

          <div className="speakers-content">
            <h3>
              <a
                href={transportation.mapLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {transportation.name}
              </a>
            </h3>
            <span className="price">{transportation.type}</span>
            <br />
            <span className="price">{transportation.address}</span>
            <span
              className="distance"
              dangerouslySetInnerHTML={{ __html: transportation.distance }}
            ></span>

            <ul className="social">
              <li>
                <a
                  href={transportation.mapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icofont-link"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t } = this.props
    return (
      <section className="speakers-area-two ptb-120">
        <div className="container">
          <div className="section-title">
            <span>{t("transportation.title")}</span>
            <h2>
              {t("transportation.subtitle")}{" "}
              <b>{t("transportation.subtitleElement")}</b> <br />
            </h2>

            <LaxDiv text={t("transportation.title")} dataPreset="driftLeft" />

            <div className="bar"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {transportationData.map((transportation) =>
              this.renderTransportation(transportation)
            )}
          </div>
        </div>
        <style jsx>{`
          .image-container {
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio */
            position: relative;
            overflow: hidden;
          }
          .image-container img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .btn-box {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
      </section>
    )
  }
}

export default withTranslation()(Transportations)
