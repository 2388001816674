import React from "react";
import { Link } from "react-router-dom";
import "isomorphic-fetch";
// import Footer from "../../Common/Footer";
import { withTranslation } from "react-i18next";
import FooterV2 from "../../v2/layout/FooterV2";

class ExhibitorApplication extends React.Component {
  state = {
    submitting: false,
    submitted: false,
    buttonState: "",
    formFields: {
      profile_name: "",
      email: "",
      phone: "",
    },
  };

  onSubmit = (e) => {
    e.preventDefault();
    const data = this.state.formFields;
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    fetch(`${apiBaseUrl}/api/exhibitor`, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ submitted: true });
      }
      let formFields = Object.assign({}, this.state.formFields);
      formFields.profile_name = "";
      formFields.email = "";
      formFields.phone = "";
      this.setState({ formFields });
    });
  };

  nameChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.profile_name = e.target.value;
    this.setState({ formFields });
  };

  emailChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.email = e.target.value;
    this.setState({ formFields });
  };

  phoneChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.phone = e.target.value;
    this.setState({ formFields });
  };

  textChangeHandler = (e) => {
    let formFields = Object.assign({}, this.state.formFields);
    formFields.message = e.target.value;
    this.setState({ formFields });
  };

  onHideSuccess = () => {
    this.setState({ submitted: false });
  };

  successMessage = () => {
    const { t } = this.props;
    if (this.state.submitted) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          style={{ marginTop: "14px" }}
        >
          <strong>{t("exhibitor.form.success.title")}</strong>
          <br />
          {t("exhibitor.form.success.message")}
        </div>
      );
    }
  };
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="page-title-area item-bg1">
          <div className="container">
            <h1>{t("exhibitor.title")}</h1>
            <ul>
              <li>
                <Link to="/">{t("exhibitor.breadcrumb.home")}</Link>
              </li>
              <li>{t("exhibitor.breadcrumb.exhibitor")}</li>
            </ul>
          </div>
        </div>

        <section className="contact-area ptb-120">
          <div className="container">
            <div
              style={{
                fontFamily: "Calibri, sans-serif",
                fontSize: "12pt",
                color: "#0D0D0D",
              }}
            >
              {/* Content sections */}
              <img
                src={require("../../../assets/images/exh1.jpg")}
                alt="speaker"
              />
              <p style={{ margin: "0cm" }}>
                <strong>
                  <u>SMEs EXHIBITOR PROFILE</u>
                </strong>
              </p>
              <ul>
                <li>
                  Small Enterprises (Textiles/Handmade, FnB, Herbs, Decorations
                  and Furnitures, Jewelries, Fashion, Accessories)
                </li>
                <li>Retailers and Wholesaler</li>
                <li>Trading Company and Reseller</li>
                <li>
                  State Owned Enterprises that already have Foster Partner
                  focusing on SME Development
                </li>
                <li>
                  Fast Moving Company Goods based in Indonesia who planning to
                  export their products to European Market
                </li>
                <li>Government of Indonesia</li>
              </ul>
              <img
                src={require("../../../assets/images/exh2.jpg")}
                alt="speaker"
              />
              <p style={{ margin: "0cm" }}>
                <strong>
                  <u>TOURISM EXHIBITOR PROFILE</u>
                </strong>
              </p>
              <ul>
                <li>
                  Tour Travel, Tour Operators, Travel Consultant based Indonesia
                </li>
                <li>Associations who related in Tourism Sector</li>
                <li>
                  State Owned Enterprises that already have Foster Partner
                  focusing on Tourism Villages
                </li>
                <li>Government of Indonesia</li>
                <li>Corporate + CSR who focused on Tourism Villages</li>
              </ul>

              <img
                src={require("../../../assets/images/exh3.jpg")}
                alt="speaker"
              />
              <p style={{ margin: "0cm" }}>
                <strong>
                  <u>WHY EXHIBIT WITH US</u>
                </strong>
              </p>
              <ul>
                <li>
                  <strong>Showcasing Sustainability Efforts</strong>
                </li>
              </ul>
              <p style={{ margin: "0cm 0cm 0cm 36pt", textAlign: "justify" }}>
                For businesses focused on eco-friendly practices, participating
                in an event with a green tourism theme provides a platform to
                showcase their sustainability efforts
              </p>
              <ul>
                <li>
                  <strong>Market Expansion</strong>
                </li>
              </ul>
              <p style={{ margin: "0cm 0cm 0cm 36pt", textAlign: "justify" }}>
                European markets often have a high demand for eco-friendly
                tourism options, making it an ideal platform for showcasing
                Indonesian Offerings
              </p>
              <ul>
                <li>
                  <strong>Brand Exposure</strong>
                </li>
              </ul>
              <p style={{ margin: "0cm 0cm 0cm 36pt", textAlign: "justify" }}>
                To showcase the uniqueness of Indonesian tourism village to
                European
              </p>
              <ul>
                <li>
                  <strong>Educational Opportunities</strong>
                </li>
              </ul>
              <p style={{ margin: "0cm 0cm 0cm 36pt", textAlign: "justify" }}>
                Learn from industry experts and gain valuable insights
              </p>
              <p style={{ margin: "0cm", textAlign: "justify" }}>
                <strong>
                  <u>VISITOR PROFILE</u>
                </strong>
              </p>
              <p style={{ margin: "0cm 0cm 0cm 36pt", textAlign: "justify" }}>
                <u>B2C</u>
              </p>
              <ul>
                <li>
                  The People who interest to consult about destinations or Tour
                  Packages
                </li>
                <li>
                  People who want to experience and learn various kinds of
                  Indonesian Culture
                </li>
                <li>A travel enthusiast</li>
                <li>Associations and Institutions</li>
                <li>Students and Locals from Europe</li>
              </ul>
              <p style={{ margin: "0cm 0cm 0cm 36pt", textAlign: "justify" }}>
                <u>B2B</u>
              </p>
              <ul>
                <li>Exporter and Importer</li>
                <li>
                  Travel tours in Europe who are interested in collaboration
                </li>
                <li>Associations based in Netherlands and Europe</li>
              </ul>
              <p style={{ margin: "0cm", textAlign: "justify" }}>
                <strong>
                  <u>WHY VISIT US</u>
                </strong>
              </p>
              <ul>
                <li>
                  <strong>Discover Unique Cultural Experiences</strong>
                </li>
              </ul>
              <p style={{ margin: "0cm 0cm 0cm 36pt", textAlign: "justify" }}>
                Attendees can immerse themselves in Indonesian culture through
                live performances, traditional arts and crafts demonstrations,
                culinary experiences, and interactive exhibits showcasing the
                country's cultural diversity
              </p>
              <ul>
                <li>
                  <strong>
                    Support Sustainable Tourism and Community Development
                  </strong>
                </li>
              </ul>
              <p style={{ margin: "0cm 0cm 0cm 36pt", textAlign: "justify" }}>
                Visitors contribute to the promotion of sustainable tourism
                practices and the economic empowerment of local communities in
                Indonesia
              </p>
              <ul>
                <li>
                  <strong>Explore Green Tourism Initiatives</strong>
                </li>
              </ul>
              <p style={{ margin: "0cm 0cm 0cm 36pt", textAlign: "justify" }}>
                Visitors can learn about eco-friendly initiatives, such as
                community-based tourism projects, conservation efforts, and
                renewable energy solutions implemented in Indonesian Villages
              </p>
              <ul>
                <li>
                  <strong>Educational and Inspirational Experience</strong>
                </li>
              </ul>
              <p style={{ margin: "0cm 0cm 0cm 36pt", textAlign: "justify" }}>
                From informative exhibits to thought-provoking discussions,
                attendees can gain new perspectives, broaden their horizons, and
                be inspired to make a positive difference in the world
              </p>
            </div>

            <div className="row h-100 align-items-center contact-form">
              <div className="col-lg-12 col-md-12 mt-5">
                <form onSubmit={this.onSubmit} id="contactForm">
                  <div className="row">
                    <div id="msgSubmit" className="h3 text-center hidden">
                      {this.successMessage()}
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="name">
                          {t("exhibitor.form.fields.name.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="profile_name"
                          id="profile_name"
                          required={true}
                          data-error={t("exhibitor.form.fields.name.error")}
                          value={this.state.formFields.profile_name}
                          onChange={this.nameChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="email">
                          {t("exhibitor.form.fields.email.label")}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          required={true}
                          data-error={t("exhibitor.form.fields.email.error")}
                          value={this.state.formFields.email}
                          onChange={this.emailChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="number">
                          {t("exhibitor.form.fields.phone.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="number"
                          id="number"
                          required={true}
                          data-error={t("exhibitor.form.fields.phone.error")}
                          value={this.state.formFields.phone}
                          onChange={this.phoneChangeHandler}
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button
                        type="submit"
                        className="btn btn-primary animated-button"
                      >
                        {t("exhibitor.form.submit")}
                      </button>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    );
  }
}

export default withTranslation()(ExhibitorApplication);
