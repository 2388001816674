import React, { useEffect, useRef } from "react";
import lax from "lax.js";

const FunctionalLaxDiv = ({ text = "Speakers", dataPreset = "driftRight" }) => {
  const divRef = useRef(null);

  useEffect(() => {
    const el = divRef.current;
    if (el) {
      lax.addElement(el);
    }

    // Cleanup function to remove the element on unmount
    return () => {
      if (el) {
        lax.removeElement(el);
      }
    };
  }, []); // Empty dependency array to run only on mount and unmount

  return (
    <div className="bg-title lax" data-lax-preset={dataPreset} ref={divRef}>
      {text}
    </div>
  );
};

export default FunctionalLaxDiv;
