import React from "react";
// import MainBanner from "../schedule/MainBanner"
import EventAgenda from "../schedule/EventAgenda";
import FooterV2 from "../v2/layout/FooterV2";
// import Footer from "../Common/Footer"

class Agenda extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <MainBanner /> */}
        <EventAgenda />
        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    );
  }
}

export default Agenda;
